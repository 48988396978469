/* eslint-disable complexity */
import React, { useRef, useState } from "react";
import SingleLevelTable from "../singleLevelTable/index";
import TabSelector from "../../../../Common/graph-table-view/TabSelector";
import Loader from "../../../../../Common/Loader";
import RadioButtonGroup from "../../../../../FormConfig/Form/Components/RadioButtonGroup";
import ExcelDownload from "../../../../../Common/ExcelDownload";
import ToggleBtnGroup from "../../../../../FormConfig/Form/Components/ToggleBtnGroup";
import { ReactComponent as MoreIcon } from "../../../../../../Assets/icons/menu.svg";
import { ReactComponent as ExpandIcon } from "../../../../../../Assets/icons/maximize-arrow.svg";
import { handleKeyPress } from "../../EKS/common/helper";
import useDetectOutsideClick from "../../../../../../CustomHooks/useDetectOutsideClick";
import TableModal from "../../EKS/component/TableModal";
import ActionsDialog from "../../../../../Common/Modal/ActionsDialog";
import { downloadExcel, noop } from "../../../../../../Utils/commonUtils";
import Chart from "./Chart";
import ExpandedGraph from "./ExpandedGraph";
import Tabs from "./Tabs";

// eslint-disable-next-line max-lines-per-function
function Graph(props) {
  const {
    graph: {
      heading,
      tabs,
      type,
      graphData,
      imagesList,
      graphtableData,
      graphTableHeader,
      graphTableFooter = [],
      list,
      calendarType = "",
      name,
      toggleType = "radio",
      active = "",
      showMoreIcon = false,
      showGraphExpandIcon = false,
      width,
    },
    id,
    dataTransferHeading,
    showExcelBtn = false,
    wrapperClass = "",
    onExcelDownload = () => {},
    graphLoading = false,
    showSortingOnTotal = false,
    activeGranularity,
    configuration,
    updateConfig = noop,
    apiKey,
  } = props;
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showGraphModal, setShowGraphModal] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    configuration?.selectedTab
      ? configuration?.tabArr.find((_) => _.value === configuration?.selectedTab)?.label
      : configuration?.tabArr?.[0]?.label
  );

  const [graphLoader, setGraphLoader] = useState(false);

  const ref = useRef();
  const ImageListSelector = (selectedProp) => {
    const { onGraphChange } = props;
    onGraphChange(name, selectedProp);
  };
  const onRadioChange = (selectedProp) => {
    const { onGraphChange } = props;
    onGraphChange(name, selectedProp, "calendarType");
  };

  const toggleBtnClick = (selectedBtn) => {
    const { onGraphChange } = props;
    onGraphChange(name, selectedBtn, "costUsageType");
  };

  useDetectOutsideClick(ref, (e) => {
    e.stopPropagation();
    setShowMoreOptions(false);
  });

  const renderList = () => {
    switch (toggleType) {
      case "radio":
        return (
          <RadioButtonGroup
            key="monthlyDaily"
            options={list}
            handleChange={(e) => onRadioChange(e.target.value)}
            value="Daily"
            childClassName="graphHeaderRadioChildClass"
            className="graphHeaderRadio"
          />
        );
      case "toggle":
        return (
          <ToggleBtnGroup
            buttonArray={list}
            toggleBtnClick={toggleBtnClick}
            active={active}
            className="renderToggleClass"
          />
        );
      default:
        return null;
    }
  };

  const getGraphExpandIcon = () =>
    showGraphExpandIcon ? (
      <div
        role="button"
        tabIndex={0}
        className="p-[4px] mr-[10px] bg-[#F0F2F6] border border-transparent rounded-[3px] cursor-pointer"
        onClick={() => setShowGraphModal(true)}
        onKeyPress={(e) => {
          handleKeyPress(e, true, setShowGraphModal);
        }}
      >
        <ExpandIcon className="expand-icon" />
      </div>
    ) : null;

  const exportDownload = async (service, granularity, subTab) => {
    setExcelLoading(true);
    try {
      const response = await onExcelDownload(service, granularity?.toUpperCase(), subTab);
      downloadExcel(response);
    } finally {
      setExcelLoading(false);
    }
  };

  const getExcelButton = (data, service) => (
    <div className="excel-button ml-[16px]">
      <ExcelDownload
        excelLoading={excelLoading}
        downloadHandler={() => {
          if (apiKey && activeGranularity) {
            exportDownload(
              apiKey,
              activeGranularity,
              configuration?.tabArr?.find((_) => _?.label === service)?.value
            );
          } else {
            onExcelDownload(name);
          }
        }}
        hasData={data ? !data.length : !(graphData?.dataset?.length || graphData?.data)}
      />
    </div>
  );

  const tabClicked = async (item) => {
    if (item?.label === selectedTab) {
      return;
    }
    setGraphLoader(true);
    setSelectedTab(item?.label);
    configuration.selectedTab = item?.value;
    const response = await configuration.serviceCall(
      apiKey,
      configuration?.name,
      activeGranularity?.toUpperCase(),
      configuration
    );
    const config = { ...configuration, ...{ data: response?.data, selectedTab: item?.value } };
    updateConfig(configuration?.name, config);
    setGraphLoader(false);
  };

  return (
    <div className={`billing_summary_wrapper s3GraphCont ${wrapperClass}`}>
      <div className="billing_summary">
        <div className="Graph_table_wrapper box-styling">
          <div
            className={
              tabs
                ? "Graph_table_heading border-bottom-grey withTabs"
                : "Graph_table_heading border-bottom-grey "
            }
          >
            <div className="d_flex heading_wrapper">
              <h3 className={`heading main-text-bold ${dataTransferHeading}`}>
                {!!heading && heading}
              </h3>
            </div>
            {!!tabs && (
              <div className="d_flex items-center">
                <div className="graphHeaderRadioParent">
                  {calendarType?.text && (
                    <div className={calendarType?.class}>{calendarType?.text}</div>
                  )}
                  {list?.length ? renderList() : null}
                </div>
                <TabSelector
                  type="cudos-images"
                  imagesList={imagesList}
                  selected={type}
                  onSelect={ImageListSelector}
                  id={id}
                />
                {showExcelBtn && getExcelButton()}
              </div>
            )}
            {showMoreIcon ? (
              <div
                className={`showMoreIcon flex h-[24] ${showMoreOptions ? "activeIcon" : ""}`}
                ref={ref}
              >
                {getGraphExpandIcon()}

                <MoreIcon
                  onClick={() => setShowMoreOptions(!showMoreOptions)}
                  className={`more-icon cursor-pointer ${showMoreOptions ? "active" : ""}`}
                />
                {showMoreOptions && (
                  <div className="more_container">
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        setShowModal(true);
                      }}
                      onKeyPress={(event) => {
                        handleKeyPress(event, true, setShowModal);
                      }}
                      className="option mb-[3px]"
                    >
                      View Table
                    </div>
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        if (apiKey && activeGranularity) {
                          exportDownload(
                            apiKey,
                            activeGranularity,
                            configuration?.tabArr?.find((_) => _?.label === selectedTab)?.value
                          );
                        } else {
                          onExcelDownload(name);
                        }
                        setShowMoreOptions(false);
                      }}
                      onKeyPress={(event) => {
                        if (apiKey && activeGranularity) {
                          handleKeyPress(event, apiKey, exportDownload, activeGranularity);
                        } else {
                          handleKeyPress(event, name, onExcelDownload);
                        }
                        setShowMoreOptions(false);
                      }}
                      className="option"
                      disabled={!graphtableData?.length}
                    >
                      Export Excel
                    </div>
                  </div>
                )}
                {showModal && (
                  <TableModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    graphTableHeader={graphTableHeader}
                    graphtableData={graphtableData}
                    graphTableFooter={graphTableFooter}
                    heading={heading}
                    downloadSheetButton={getExcelButton}
                    tabs={configuration.tabArr}
                    selectedTab={selectedTab}
                    configuration={configuration}
                    activeGranularity={activeGranularity}
                    apiKey={apiKey}
                  />
                )}
              </div>
            ) : null}
          </div>
          <div>
            <Tabs
              tabs={configuration?.tabArr}
              tabClicked={tabClicked}
              selected={selectedTab}
              wrapperClass="mt-[16px] justify-center flex"
              isLoading={graphLoader}
            />
          </div>
          <div className="data_renderer">
            {graphLoading || graphLoader ? (
              <Loader />
            ) : (
              <div>
                {type === "table" ? (
                  <div className="table_container_wrapper">
                    {graphtableData?.length ? (
                      <SingleLevelTable
                        headings={graphTableHeader}
                        data={graphtableData}
                        sortedIcon
                        footer={graphTableFooter}
                        newFooter={graphTableFooter}
                        heading={`table_${id}`}
                        V2
                        lastColumnSticky
                        showSortingOnTotal={showSortingOnTotal}
                      />
                    ) : (
                      <div className="no_data">
                        <h5>No Data Available</h5>
                      </div>
                    )}
                  </div>
                ) : (
                  <Chart {...props} updatedWidth={width} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <ActionsDialog
        open={showGraphModal}
        data={{
          config: {
            heading,
            type: "Custom",
            actions: false,
            dialogClass: "modal_table_container graph_modal",
            body: <ExpandedGraph {...props} selectedTab={selectedTab} />,
          },
        }}
        handleClose={() => setShowGraphModal(false)}
        showCancelBtn={false}
        showCloseIcon
      />
    </div>
  );
}

export default Graph;
