/* eslint-disable no-restricted-syntax */
import React from "react";
import moment from "moment";
import { toast } from "react-toastify";
// import cloneDeep from "lodash.clonedeep";
// import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CryptoJS from "crypto-js";
import store from "../store";
// import preferencesRoutes from "../Config/PreferencesRoutes";
import actions from "../Actions";
import { BudgetAlertMenu } from "../Components/CK-Lens/Dashboard/NotificationsV2/BudgetAlert/constants";
// eslint-disable-next-line import/no-cycle
import SimpleAccordion from "../Components/Quote-generator/Accordian";
import {
  ModuleNameLegacyLens,
  ModuleNameAzureInsights,
  ModuleNameGCPInsights,
  ModuleNameTuner,
} from "../Constants/textConstant";
import Routes from "../Config/Routes";
import { ReactComponent as ToastErrorIcon } from "../Assets/icons/toastErrorIcon.svg";
import { ReactComponent as ToastSuccessIcon } from "../Assets/icons/toastSuccessIcon.svg";
import { MODULE_ENUM, DASHBOARD_ENUM } from "../Constants/finops/Constants";
// export const process.env.REACT_APP_ENCRYPTION_PUBLIC_KEY = ":tLan&2.)qIJdqP_CK_ANALYTICS_D#tfgfJJ}ZJW-.i";

export const getEncryptedData = (data) => {
  const ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    process.env.REACT_APP_ENCRYPTION_PUBLIC_KEY
  ).toString();
  const encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(ciphertext));
  return encData;
};

export const getDecryptedData = (ciphertext) => {
  if (ciphertext) {
    try {
      const decData = CryptoJS.enc.Base64.parse(ciphertext).toString(CryptoJS.enc.Utf8);
      const bytes = CryptoJS.AES.decrypt(decData, process.env.REACT_APP_ENCRYPTION_PUBLIC_KEY);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      const parsedJsonData = JSON.parse(decryptedData);
      return parsedJsonData;
    } catch (e) {
      return null;
    }
  } else {
    return null;
  }
};

export const setLocalStorageUserData = (data) => {
  const encryptedData = getEncryptedData(data);
  localStorage.setItem("userData", encryptedData);

  // return localStorage.setItem("userData", JSON.stringify(data));
};

export const getLocalStorageUserData = () => {
  const getUserData = getDecryptedData(localStorage.getItem("userData"));
  return getUserData || null;
  // return JSON.parse(localStorage.getItem("userData")) || null;
};

export const removeDuplicateObjectsName = (arr) => {
  const uniqueObjects = {};

  for (const obj of arr) {
    if (obj.name !== undefined) {
      const { name } = obj;

      if (!uniqueObjects[name]) {
        // If an object with the same name doesn't exist, add it to uniqueObjects
        uniqueObjects[name] = obj;
      }
    }
  }

  // Convert the uniqueObjects back to an array
  const uniqueArray = Object.values(uniqueObjects);

  return uniqueArray;
};

export const MAX = 2000;
export const isLoggedIn = () => {
  const parsedData = getLocalStorageUserData();
  if (parsedData) {
    return parsedData?.currentUser?.type;
  }
  return false;
};

export const isAzureInsightsModule = () => {
  const userData = getLocalStorageUserData();
  return userData?.tokens?.moduleName === ModuleNameAzureInsights;
};

export const isTunerModule = () => {
  const userData = getLocalStorageUserData();
  if (!userData) {
    return false;
  }
  return userData?.tokens?.moduleName === ModuleNameTuner;
};

export const isGcpInsightsModule = () => {
  const userData = getLocalStorageUserData();
  return userData?.tokens?.moduleName === ModuleNameGCPInsights;
};

const getToastIcon = (type) => {
  const toastIcon = { success: <ToastSuccessIcon />, error: <ToastErrorIcon /> };
  return toastIcon?.[type];
};

let toastMsg = {};

const showToast = (type, text, timer) => {
  toast[type](text, {
    draggable: false,
    autoClose: timer,
    closeOnClick: false,
    icon: getToastIcon(type),
  });
  toastMsg[text] = new Date().getTime();
};

export const getToast = (type, text, timer = 3000) => {
  const { pathname } = window.location;
  const currentPage = pathname?.split("/")?.pop();
  if (toastMsg.currentPage !== currentPage) {
    toastMsg = {};
    toastMsg.currentPage = currentPage;
  }

  if (!toastMsg[text] || type === "success") {
    showToast(type, text, timer);
  } else if (
    new Date().getTime() - toastMsg[text] >
    (process.env.REACT_APP_TOAST_NEXT_MESSAGE_TIMER || 4000)
  ) {
    showToast(type, text, timer);
  }
};

export const renturnPartnerEmailTooltipStyle = () => ({
  borderRadius: "3px",
  padding: " 6px",
  fontWeight: "500",
  backgroundColor: "#343D57",
  boxShadow: "-5px 6px 7px #343D572B",
  background: "none",
  minWidth: "334px",
  lineHeight: 1.3,
});

export const isRootUserAdmin = (permission) => {
  const userData = getLocalStorageUserData() || null;

  const {
    loggedInUser: { roleType },
  } = userData;

  return (
    permission &&
    (roleType?.split("-")?.shift() === "CLN" || roleType?.split("-")?.shift() === "PTNR")
  );
};

export const getBudgetTabList = (customerMenuListClone) => {
  const filteredValue = customerMenuListClone.filter(
    (item) => item.type === "ALERT" && item.parent.name === "Budget Alerts"
  );
  if (!filteredValue?.length) {
    return BudgetAlertMenu;
  }
  return filteredValue;
};

export const isAutoDashboard = () => {
  const userData = getLocalStorageUserData();
  return userData?.tokens?.moduleName === "ckauto";
};

export const isTunerDashboard = () => {
  const userData = getLocalStorageUserData();
  return userData?.tokens?.moduleName === "tuner";
};

export const isBilldeskModule = () => {
  const userData = getLocalStorageUserData();
  return userData?.tokens?.moduleName === "Billdesk";
};

export const isLens = () => {
  const userData = getLocalStorageUserData();
  return userData?.tokens?.moduleName === "lens";
};

export const isLensModule = (userData) => {
  if (!userData) {
    return false;
  }
  const {
    tokens: { moduleName },
  } = userData;
  return moduleName === "lens";
};

export const isCurrentModule = (currentModule, moduleName) => {
  if (!currentModule || !moduleName) {
    return false;
  }
  return currentModule === moduleName;
};

export const isLegacyLensModule = (userData) => {
  if (!userData) {
    return false;
  }
  const {
    tokens: { moduleName },
  } = userData;
  return moduleName === ModuleNameLegacyLens;
};

export const noop = () => true;

export const isBilldeskRO = (role, moduleName) => role === "READ" && moduleName === "Billdesk";

export const filterList = (term, key) => (ele) =>
  ele?.[key]?.toLowerCase().includes(term.toLowerCase());

const logoutSync = () => {
  if (localStorage?.logoutUser === "true") {
    localStorage.removeItem("logoutUser");
  }

  localStorage.setItem("logoutUser", true);
};

export const logout = () => {
  localStorage.removeItem("userData");
  localStorage.removeItem("BillingMonth");
  localStorage.removeItem("BillingYear");
  localStorage.removeItem("partnerName");
  localStorage.removeItem("partnerInfo");
  localStorage.removeItem("ckauto_query");
  localStorage.removeItem("legacy_arn_details");
  localStorage.removeItem("utilizationDates");

  // for analytics -> coverage
  localStorage.removeItem("dashboardDates");
  localStorage.removeItem("selectedDate");
  localStorage.removeItem("selectedPeriod");
  localStorage.removeItem("selectedCalenderRange");
  // store.dispatch(actions.SidebarListRenderAction("LOGOUT_USER", []));
  // we set logoutButtonClick in local storage upon click of logout to let Private routes know if user is actually clicking logout or trying to access secure pages without logging in..
  localStorage.setItem("logoutButtonClick", "true");
  logoutSync();
  window.location.reload();
};

export const copyToClipboard = async (event, item, key) => {
  getToast("success", "Content Copied");
  await navigator.clipboard.writeText(item[key]);
};

export const changeStructureForDropdown = (items) =>
  items.map((item) => ({
    label: item,
    value: item,
    checked: false,
  }));

export const getCurrencySymbol = () => localStorage.getItem("currency") || "$";

export const numberWithCommasandDecimal = (x = 0, toFixed = 2, currencyRequired = true) => {
  if (typeof x === "number") {
    if (x < 0) {
      const withoutNegative = x * -1;
      // eslint-disable-next-line eqeqeq
      if (x.toFixed(toFixed) == 0.0) {
        return `${getCurrencySymbol()}${withoutNegative
          .toFixed(toFixed)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
      }
      return `-${currencyRequired ? getCurrencySymbol() : ""}${withoutNegative
        .toFixed(toFixed)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }
    if (x > 99 && !currencyRequired) {
      if (x > 99999) return x.toExponential(2);
      return Math.round(x).toLocaleString();
    }
    return `${currencyRequired ? getCurrencySymbol() : ""}${x
      .toFixed(toFixed)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }
  return x;
};

export const formateNumber = (x = 0) => `$${x.toFixed(2)}`;

export const formateStringTypeNumber = (x = 0, showPrefix = true) => {
  const num = +x;
  const prefix = showPrefix ? getCurrencySymbol() : "";
  return `${prefix}${num.toFixed(2)}`;
};

export const numberWithCommasandDecimalPercentage = (x = 0, toFixed = 2 /* , row = {} */) => {
  if (typeof x === "number") {
    if (x < 0) {
      const withoutNegative = x * -1;
      // eslint-disable-next-line eqeqeq
      if (x.toFixed(toFixed) == 0.0) {
        return `${withoutNegative
          .toFixed(toFixed)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`;
      }
      return `-${withoutNegative
        .toFixed(toFixed)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`;
    }
    return `${x
      .toFixed(toFixed)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`;
  }
  return x;
};

export const checkHandler = (Headers, columns = []) => {
  let found = [];
  found = columns.filter((item) => item.id in Headers);
  return found;
};
export const numberWithCommas = (x) => x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const compareDates = (compareDate, archivalDate) => {
  const formattedCompareDate = moment(compareDate).format("YYYY-MM-DD");
  const formattedArchivalDate = moment(archivalDate).format("YYYY-MM-DD");
  if (moment(formattedCompareDate).isBefore(moment(formattedArchivalDate))) {
    return moment(archivalDate).format("YYYY-MM-DD");
  }
  if (moment(formattedCompareDate).isAfter(moment(formattedArchivalDate))) {
    return moment(compareDate).format("YYYY-MM-DD");
  }
  return moment(compareDate).format("YYYY-MM-DD");
};

export const messageGenerator = (newDashboardStartDate) => {
  const differenceBetweenCurrentDateAndArchivalDate = moment().diff(
    moment(newDashboardStartDate),
    "month"
  );

  return `Showing data for last ${
    differenceBetweenCurrentDateAndArchivalDate + 1
  } months please select range within this`;
};

export const startDateCalculator = (accountStartDate, accountEndDate) => {
  const formattedstartDate = moment(accountStartDate);
  const formattedEndDate = moment(accountEndDate);
  const sixmonthBackDate = moment().subtract(6, "months").startOf("month").format("MM/DD/YYYY");
  const calculatedStartDate =
    formattedstartDate.diff(sixmonthBackDate, "days") < 0 &&
    formattedEndDate.diff(sixmonthBackDate, "days") >= 0
      ? sixmonthBackDate
      : formattedstartDate;
  return calculatedStartDate;
};

export const endDateCalculator = (accountStartDate, accountEndDate) => {
  const formattedendDate = moment(accountEndDate);
  const formattedstartDate = moment(accountStartDate);
  const differenceDays = formattedendDate.diff(formattedstartDate, "days");
  if (differenceDays < 31) {
    if (differenceDays < 2) {
      return formattedendDate;
    }
    if (formattedstartDate.month() === formattedendDate.month()) {
      return formattedendDate;
    }
    return formattedstartDate.endOf("month");
  }
  if (formattedendDate.month() === moment().subtract(1, "months").month()) {
    return formattedendDate;
  }
  return formattedendDate.diff(moment(), "days") < 0
    ? formattedendDate
    : moment().subtract(1, "months").endOf("month");
};

export const buttonDataCreator = (getButtonArray) =>
  getButtonArray?.data?.data.map((item) => ({
    key: item.toLowerCase(),
    value: item.replace(/_/g, " ").toLowerCase(),
    include: "Include Only",
    searchText: "",
    childList: {},
    arrayList: [],
    selectAll: false,
    tagList: {},
    isApplied: false,
  }));

export const createFiltersV2 = (getButtonArray) =>
  getButtonArray?.data?.data.map((item) => ({
    key: item.toLowerCase(),
    value: item.replace(/_/g, " ").toLowerCase(),
    include: "Include Only",
    searchText: "",
    childList: [],
    selectChildList: [],
    selectAll: false,
    enum: item,
    open: false,
  }));

export const calculateNewDashboardDates = (dashboardStartDate, accountStartDate, format) => {
  if (dashboardStartDate) {
    return moment(dashboardStartDate).format(format);
  }
  return moment(accountStartDate).format(format);
};

export const dateFormatter = (date, format) => moment(date).format(format);

export const customEndDateHandler = (minDate, maxDate) => {
  if (moment(maxDate).diff(moment(minDate), "days") < 31) {
    if (moment(maxDate).diff(moment(minDate), "days") < 2) {
      return moment(maxDate);
    }
    if (moment(minDate).month() === moment(maxDate).month()) {
      return moment(maxDate);
    }
    return moment(minDate).endOf("month");
  }
  if (moment(maxDate).month() === moment().subtract(1, "months").month()) {
    return moment(maxDate);
  }
  return moment().subtract(1, "months").endOf("month");
};

export const datePickerClearSelecetion = (minDate, maxDate, granualityType, onDateChange) => {
  const difference = moment(maxDate).diff(moment(minDate), "days");
  if (difference < 31) {
    if (moment(minDate).month() === moment(maxDate).month()) {
      return onDateChange(moment(minDate), moment(maxDate), true);
    }
    return onDateChange(moment(minDate), moment(minDate).endOf("month"), true);
  }
  if (granualityType === "Monthly") {
    return onDateChange(
      difference < 182 ? moment(minDate) : moment().startOf("month").subtract(6, "months"),
      moment(maxDate).month() === moment().subtract(1, "months").month()
        ? moment(maxDate)
        : moment().subtract(1, "months").endOf("month"),
      true
    );
  }
  if (granualityType === "Daily") {
    return onDateChange(
      moment().startOf("month").subtract(1, "months"),
      moment(maxDate).month() === moment().subtract(1, "months").month()
        ? moment(maxDate)
        : moment().subtract(1, "months").endOf("month"),
      true
    );
  }
  return null;
};

export const colorCodesClasses = (currentValue = 0, prevValue = 0, decimalCount = 2) => {
  const cVal = currentValue;
  const pVal = prevValue;

  const percentageValue = (
    ((cVal.toFixed(decimalCount) - pVal.toFixed(decimalCount)) /
      (pVal.toFixed(decimalCount) < 0 ? pVal.toFixed(0) * -1 : pVal.toFixed(decimalCount))) *
    100
  ).toFixed(2);
  switch (true) {
    case percentageValue === 0:
      return "white";
    case percentageValue > 5:
      return "red";
    case percentageValue > 0 && percentageValue <= 5:
      return "blue";
    case percentageValue < 0:
      return "green";
    default:
      return "white";
  }
};

export const colorCodesClassesV2 = (currentValue = 0, prevValue = 0) => {
  const cVal = currentValue;
  const pVal = prevValue;

  const percentageValue = (
    ((cVal.toFixed(2) - pVal.toFixed(2)) /
      (pVal.toFixed(2) < 0 ? pVal.toFixed(2) * -1 : pVal.toFixed(2))) *
    100
  ).toFixed(2);
  switch (true) {
    case percentageValue === 0:
      return "!text-black";
    case percentageValue > 5:
      return "!text-[#DB1104]";
    case percentageValue > 0 && percentageValue <= 5:
      return "!text-[#0A3C87]";
    case percentageValue < 0:
      return "!text-[#166C08]";
    default:
      return "!text-black";
  }
};

export const calculateTime = (timer) => `00:${Math.floor(timer / 60)}:${timer % 60}`;

export const returnSingleRowCostDates = (singleRow, key) =>
  singleRow.costDates[key] > 100 || singleRow.costDates[key] < -100
    ? numberWithCommasandDecimal(Number(singleRow.costDates[key]), 0)
    : numberWithCommasandDecimal(Number(singleRow.costDates[key]), 2);

export const getFormattedDate = (date) => new Date(date).getTime();
export const getUpperFormmattedString = (string = "") => string.toUpperCase();

export const isEmpty = (obj = {}) => Object?.keys(obj).length === 0;

export const isDateValid = () => {
  const updatedUrl = new URLSearchParams(window.location.search);
  const state = store.getState();
  const accountDate = state.AccountDateReducer;
  const { accountEndDate, accountStartDate } = accountDate;

  if (
    !moment(`${updatedUrl.get("year")}-${updatedUrl.get("month")}-01`).isBetween(
      moment(accountStartDate),
      moment(accountEndDate),
      "month",
      "[]"
    )
  ) {
    return false;
  }
  return true;
};

export const downloadExcel = (response) => {
  if (response.headers["content-length"] === "0") {
    getToast("error", "No Data Available");
  } else {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", response.headers.filename);
    link.click();
    getToast("success", "Successfully Downloaded!");
  }
};

// url validation function for handling invalidate date setting explicitily
export const validateUrlDate = (date, dashboardStartDate, dashboardEndDate, type) => {
  const dateType =
    type === "startDate"
      ? startDateCalculator(dashboardStartDate)
      : endDateCalculator(dashboardStartDate, dashboardEndDate);
  if (moment(date).isBetween(dashboardStartDate, dashboardEndDate, undefined, "[]")) {
    return date;
  }
  return dateType;
};

export const checkDateValidity = (
  startDateValue,
  endDateText,
  accountStartDate,
  accountEndDate
) => {
  if (moment(startDateValue).isSameOrAfter(endDateText)) {
    return [accountStartDate, accountEndDate];
  }
  return [startDateValue, endDateText];
};

// url validation function for handling granularity and chartType setting explicitily
export const validateUrlChartType = (selectedType = "", totalGroups = []) => {
  const filterGroupBy = totalGroups.filter(
    (singleGroup) =>
      singleGroup?.toLowerCase().replace(/\s/g, "") ===
      selectedType?.toLowerCase().replace(/\s/g, "")
  );
  const returnGroup = filterGroupBy.length ? filterGroupBy[0] : totalGroups[0];
  return returnGroup;
};

// Optimized url validation function for handling toggleValue setting explicitly
export const validateUrlToggle = (toggleValue) =>
  toggleValue?.toLowerCase() === "true" ? "true" : "false";

export const capitalizeFirstWord = (str) => {
  if (typeof str !== "string") {
    throw new Error("Input must be a string");
  }
  if (str.length === 0) {
    return str;
  }
  const words = str.split(" ");
  words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();
  return words.join(" ");
};

export const removeStringFromArray = (array, stringToRemove) => {
  // Use the filter() method to create a new array
  // with elements that pass the provided test function
  const newArray = array.filter(
    (element) =>
      // Check if the current element is not equal to the stringToRemove
      element !== stringToRemove
  );

  // Return the new array without the stringToRemove
  return newArray;
};
/**
 * This function validates the selected group by option for URL, and returns the corresponding value.
 *
 * @param {string} selectedGroupBy - The selected group by option.
 * @param {Array} groupBylist - The list of group by options.
 * @param {Array} tagList - The list of tags.
 *
 * @returns {string} The validated group by option.
 */

export const getSelectedGroupByUrl = () => {
  const { tokens: { moduleName } = {} } = getLocalStorageUserData() || {};
  const moduleData = {
    lens: "SERVICE",
    [ModuleNameAzureInsights]: "METER_CATEGORY",
    finops: "CUSTOMERS",
  };
  return moduleData[moduleName] || moduleData?.lens;
};
export const validateUrlGroupBy = (selectedGroupBy, groupBylist = [], tagList = []) => {
  // Check if the selected group by option includes "Tag:"
  if (selectedGroupBy.toLowerCase().includes("tag:")) {
    // Check if the tagList includes the selected group by option
    if (
      tagList?.includes(selectedGroupBy?.replace(/^Tag:\s*/i, "")) ||
      tagList?.includes(selectedGroupBy?.replace(/^tag:\s*/i, ""))
    ) {
      return capitalizeFirstWord(selectedGroupBy);
    }
    // If the tagList does not include the selected group by option, return "SERVICE"
    return selectedGroupBy || getSelectedGroupByUrl();
  }
  // Remove underscores, convert to lowercase, and remove spaces from the selected group by option
  const selectedGroup = selectedGroupBy.replace(/_/g, "").toLowerCase().trim().replace(/\s/g, "");

  // Create an updated list of group by options without underscores and in lowercase
  const updatedList = groupBylist.map((item) => ({
    key: item.replace(/_/g, "").toLowerCase().trim(),
    label: item,
  }));

  // Filter the updated list for the selected group by option
  const filteredData = updatedList.filter((item) => item.key === selectedGroup);

  // If the filtered list has at least one item, return its label, otherwise return "SERVICE"
  return filteredData.length ? filteredData[0]?.label : getSelectedGroupByUrl();
};

export const encodeURL = (url) => encodeURIComponent(JSON.stringify(url));
export const decodeURL = (url) => decodeURIComponent(url);

export const removeDuplicates = (arr) => [...new Set(arr)];

export const removeDuplicatesIgnoringKey = (arr, keyToIgnore) => {
  const unique = new Map();
  return arr.filter((item) => {
    const keyValue = item[keyToIgnore];
    if (!unique.has(keyValue)) {
      unique.set(keyValue, item);
      return true;
    }
    return false;
  });
};

export const removeDuplicateObjects = (arr) => {
  const uniqueObjects = {};

  for (const obj of arr) {
    if (obj.id !== undefined) {
      const { id } = obj;

      if (!uniqueObjects[id]) {
        // If an object with the same ID doesn't exist, add it to uniqueObjects
        uniqueObjects[id] = obj;
      }
    }
  }

  // Convert the uniqueObjects back to an array
  const uniqueArray = Object.values(uniqueObjects);

  return uniqueArray;
};
export const sortByName = (data, key) =>
  data?.sort((p1, p2) => {
    if (p1?.[key]?.toLowerCase() < p2?.[key]?.toLowerCase()) return -1;
    if (p1?.[key]?.toLowerCase() > p2?.[key]?.toLowerCase()) return 1;
    return 0;
  });
export const sortFlatArray = (data) =>
  data.sort((p1, p2) => {
    if (p1?.toLowerCase() < p2?.toLowerCase()) return -1;
    if (p1?.toLowerCase() > p2?.toLowerCase()) return 1;
    return 0;
  });
export const isDateBefore = (beforeDate, afterDate) => {
  const startDate = moment(beforeDate);
  const endDate = moment(afterDate);
  if (startDate.isBefore(endDate)) {
    return true;
  }
  return false;
};

export const setUserMetaData = async (
  commonService,
  SaveUserDataAction,
  data = undefined,
  id = undefined,
  name = undefined,
  options = {}
) => {
  const userData = data || getLocalStorageUserData();
  const userDataFromStore = store.getState().userReducer?.userData;
  userData.tokens.mavId = id || userDataFromStore?.tokens?.mavId;
  userData.tokens.partnerLevelMavId = userDataFromStore?.tokens?.partnerLevelMavId || false;
  userData.tokens.xAuthUserMav = name || userDataFromStore?.tokens?.xAuthUserMav;
  userData.tokens.customerId = userDataFromStore?.tokens?.customerId;
  userData.tokens.xAuthUserCustomer = userDataFromStore?.tokens?.xAuthUserCustomer;
  if (Object.keys(options).length) {
    // Finops Module checks to add MAV id at partner level (only for finops)
    const {
      finopsConfig: { isdashboardOnFinops, customerId },
    } = options;
    if (isdashboardOnFinops && userDataFromStore.tokens.customersMID) {
      userData.tokens.customersMID[customerId] = id || userDataFromStore?.tokens?.mavId;
    }
  }
  SaveUserDataAction("SAVE_USER_DATA", userData);
  setLocalStorageUserData(userData);
};

export const applyLimit = (value = 0, type = "number") => {
  let returnValue = 0;
  if (type === "percent") {
    if (value < 0) {
      returnValue = 0;
    } else if (value > 100) {
      returnValue = 100;
    } else {
      returnValue = value;
    }
  } else {
    returnValue = value < 0 ? 0 : value;
  }
  return returnValue;
};

export const renderYear = (value) => {
  switch (value) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    case 4:
    case 5:
      return "th";
    default:
      return "";
  }
};

export const removeErrorPage = (statusCode) => {
  const status = 200;
  const message = "";
  const checkStatusCode = [500, 503, 403, 786, 715, 785];
  if (checkStatusCode.includes(statusCode)) {
    store.dispatch(actions.ErrorAction(status, { status, errorMessage: message }));
  }
};

export const hideToggleAndMav = () =>
  window.location.pathname === Routes?.AZURE_LENS_ACCOUNT_ONBOARDING_Manual ? "invisible" : "";

// export const refreshSidebarForReports = async (sidebarList, commonService, props) => {
//   const {
//     actions: { SidebarListRenderAction },
//   } = props;
//   const tagList = await getTagList(commonService);
//   const reportsData = tagList.map((item) => returnTagItem(item));
//   const updateSidebarList = [...sidebarList];
//   const reportsIndex = updateSidebarList.findIndex((item) => item.name === "Reports");
//   updateSidebarList[reportsIndex].dashboardServices = reportsData;
//   SidebarListRenderAction("RENDER_DASHBOARDLIST", updateSidebarList);
// };

export const calculateCoverageStartDate = (date, accountEndDate) =>
  moment(date).diff(moment(accountEndDate).subtract(1, "months").startOf("month"), "days") > 0
    ? moment(date)
    : moment(accountEndDate).subtract(1, "months").startOf("month");

export const calculateCoverageEndDate = (date, accountEndDate) => {
  if (moment(accountEndDate).diff(moment(date), "days") < 2) {
    return moment(accountEndDate);
  }
  return moment(accountEndDate).subtract(2, "days");
};

/**
 * Use below function when one wants to
 * sync multiple tabs.
 * eg: - switch Mav and users
 */
export const syncTabs = () => {
  if (localStorage?.syncTabs === "true") {
    localStorage.removeItem("syncTabs");
  }

  localStorage.setItem("syncTabs", true);
};

export const CheckCost = (value, costInclude = true) => {
  const checkCostBetween = costInclude ? true : value < -0.0049 || value > 0.0049;
  return checkCostBetween;
};

/**
 * Checks if the user is using a Lens module or not.
 * @param {object} userData - The user data object containing the module name.
 * @returns {boolean} - `true` if the user is not using the "ckauto" module, `false` otherwise.
 */

export const getCustomerBucketName = () => {
  const state = store.getState();
  const customerBucketName = state?.CkAutoReducer?.customerBucketNameRead;
  return customerBucketName;
};

export const getLensReducerData = (key = "") => {
  const state = store.getState();
  const customerBucketName = state?.LegacyLensReducer?.[key];
  return customerBucketName;
};

export const getCustomerExternalIDRead = () => {
  const state = store.getState();
  const customerExternalIDRead = state?.CkAutoReducer?.customerExternalIDRead;
  return customerExternalIDRead;
};

export const getCustomerExternalIDWrite = () => {
  const state = store.getState();
  const customerExternalIDWrite = state?.CkAutoReducer?.customerExternalIDWrite;
  return customerExternalIDWrite;
};

/**
 * Scrolls to the top of the option wrapper element with a smooth animation.
 */
export const scrollToTop = () => {
  document.getElementsByClassName("option_wrapper")[0]?.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};

/**
 * Converts a string to a boolean value.
 * @param {string} str - The string to convert.
 * @returns {boolean} - The boolean value.
 */
export const stringToBoolean = (str = "") => str?.toLowerCase() === "true";

export const getPartnerDisplayName = (userData) => {
  // to do
  if (userData?.currentUser?.type === "PARTNER") {
    return userData?.currentUser?.displayName || userData?.currentUser?.name || "";
  }
  if (userData?.currentUser?.type === "CUSTOMER") {
    // const partnerInfo = JSON.parse(localStorage?.getItem("partnerInfo")) || {};
    const partnerDisplayName = userData?.displayName || userData?.partnerName || "";
    return partnerDisplayName;
  }
  return "";
};

export const getCookieByName = (name) => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i].trim();

    if (cookie.indexOf(`${name}=`) === 0) {
      return cookie.substring(name.length + 1, cookie.length);
    }
  }
  return null;
};

export const renderQuoteList = (data) => (
  <div>
    {data?.length ? <SimpleAccordion data={data} /> : <p className="no_data">No data Available</p>}
  </div>
);

export const returnDate = (timeStamp) => {
  const date = new Date(timeStamp);
  return moment(date).format("DD-MMM-YYYY HH:mm:ss");
};
export const isSignUpEnabled = (Obj) => {
  const htmlSignupArray = [
    "isCkAutoEnabled",
    "isLegacyLensEnabled",
    "isAzureInsightsEnabled",
    "isGcpInsightsEnabled",
    // "isTunerEnabled",
  ];

  if (Obj?.isTunerEnabled && !Obj?.isTunerModuleEnabled) {
    htmlSignupArray.push("isTunerEnabled");
  }

  const array = [];
  Object.keys(Obj).forEach((item) => {
    if (item.includes("Enabled")) {
      array.push({ name: item, enabled: Obj[item] });
    }
  });
  return array.filter((item) => item.enabled === true && htmlSignupArray.includes(item.name))
    .length;
};

export const genRanHex = (size) =>
  [...Array(size)].map(() => Math.floor(Math.random() * 16)?.toString(16))?.join("");

export const pushEventToGTM = (eventObj) => {
  if (eventObj && ["auto.cloudkeeper.com", "lens.cloudkeeper.com"].includes(window.location.host)) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(eventObj);
  }
};

export const checkIfReloadNeeded = () => {
  // role change check
  const localStorageData = getLocalStorageUserData();
  const storeData = store.getState()?.userReducer?.userData;
  const errorCode = store?.getState()?.ErrorReducer?.errorCode;
  if (errorCode && errorCode === 401) {
    return false;
  }
  if (localStorageData?.currentUser?.type !== storeData?.currentUser?.type) {
    return true;
  }
  // role is partner
  if (
    localStorageData?.currentUser?.type === "PARTNER" &&
    storeData?.currentUser?.type === "PARTNER"
  ) {
    // if ids are different
    if (localStorageData?.currentUser?.id !== storeData?.currentUser?.id) {
      return true;
    }
    // if moduleid is different
    if (localStorageData.tokens?.moduleId !== storeData?.tokens?.moduleId) {
      return true;
    }
  }

  // role is customer
  if (
    localStorageData?.currentUser?.type === "CUSTOMER" &&
    storeData?.currentUser?.type === "CUSTOMER"
  ) {
    if (localStorageData?.currentUser?.id !== storeData?.currentUser?.id) {
      return true;
    }
    // if mavId is different
    const moduleLength = store?.getState()?.sidebarReducer?.moduleList?.length;
    if (moduleLength < 3 && localStorageData?.tokens?.mavId !== storeData?.tokens?.mavId) {
      return true;
    }
  }
  return false;
};

export const returnCheckedObj = (value, array = []) => {
  const updatedOptions = [...array];
  updatedOptions.forEach((option) => {
    const optionClone = option;
    const isItemChecked = value?.find((val) => val.label === option.label);
    if (!isItemChecked) {
      optionClone.checked = false;
    } else {
      optionClone.checked = true;
    }
  });
  return updatedOptions;
};

export const returnLength = (selectedItems) => {
  const length = selectedItems ? selectedItems.length : 0;
  const result = `+${length - 6}`;
  return result;
};

export const modalTitleRender = (modalTitle, options, selectedItems, checkReadOnly) => (
  <>
    {`Select ${modalTitle} (${options.length})`}
    {selectedItems?.length ? (
      <span>
        Selected:
        <span className={`${!checkReadOnly ? "blue_bg" : ""} grey_box`}>
          {" "}
          {selectedItems?.length < 10 ? `0${selectedItems?.length}` : `${selectedItems?.length}`}
        </span>
      </span>
    ) : null}
  </>
);

export const selectedOptionsFirst = (list, open, value) => {
  if (value?.length) {
    return list.sort((a, b) => Number(b.checked) - Number(a.checked));
  }
  return list;
};

// Function to remove specified keys from an object
export const removeKeysFromObject = (obj, keysToRemove) => {
  const newObj = { ...obj };
  keysToRemove.forEach((key) => delete newObj[key]);
  return newObj;
};

export const isLoggedinCustomer = (moduleList, user) => {
  if (moduleList?.length > 1) {
    const { currentUser } = user || getLocalStorageUserData() || {};
    if (currentUser?.type === "CUSTOMER") {
      return false;
    }
    return true;
  }
  return false;
};
export const capitalizeFirstLetter = (str) =>
  str.replace(/^\w/, (firstLetter) => firstLetter.toUpperCase());

// Function to fix nested ternaries linting error
export const condtitionalIf = (condition, then, otherwise) => (condition ? then : otherwise);

export const extractProductID = (str) => {
  const inputString = str;
  const regex = /\(([^)]+)\)/; // Regular expression to match text within parentheses

  const match = inputString.match(regex);
  if (match && match.length >= 2) {
    return match[1];
  }
  return "";
};

export const getModule = (id, paramName = "", literal = "?") => {
  if (id) {
    return `${literal}${paramName}=${id}`;
  }
  return "";
};

// Function to return module form url
export const getModuleFromurl = (link) => {
  const DashboardRoutes = store.getState()?.sidebarReducer?.dashboardRoutes;
  const url = link || window.location.pathname;
  const openedRoute = DashboardRoutes?.find((item) =>
    url?.toLowerCase()?.includes(item?.path?.toLowerCase())
  );
  return openedRoute?.props?.module || {};
};

export const generateMonthlyDatesNew = (accountStartDate) => {
  const startDate = moment(accountStartDate);
  const endDate = moment();
  const result = [];

  while (moment(startDate.format("YYYY-MM-01")).isBefore(endDate)) {
    result.push(startDate.format("YYYY-MM-01"));
    startDate.add(1, "month");
  }
  return [...result].reverse();
};

export const generateMonthlyDatesForMetering = () => {
  const result = [];
  for (let i = 0; i < 3; i += 1) {
    result?.push(moment().subtract(i, "months").format("YYYY-MM-01"));
  }
  return result;
};

// return curreunt date e.g september-2023
export const getDefaultMonthDate = () => {
  const current = new Date();
  const currentYear = current.getFullYear();
  const currentMonth = current.getMonth() + 1; // Month is 0-indexed, so add 1
  return { currentYear, currentMonth };
};

// transform the metering record response to table data
export const getTransformedData = (data) => {
  const dataArray = Object.keys(data).map((date) => ({
    date: moment(date)?.format("DD-MMMM-YYYY"),
    ec2Savings: data?.[date]?.ec2Savings?.toFixed(2),
    rdsSavings: data?.[date]?.rdsSavings?.toFixed(2),
    savings: data?.[date]?.savings?.toFixed(2),
    meteringQuantity: data?.[date]?.meteringQuantity?.toFixed(2),
    meteringSent: data?.[date]?.meteringSent,
    meteringSentDate: data?.[date]?.meteringSentDate
      ? moment(data?.[date]?.meteringSentDate)?.format("DD-MMMM-YYYY")
      : "",
    meteringQuantitySent: data?.[date]?.meteringQuantitySent?.toFixed(2),
    meteringQuantityUnsent: data?.[date]?.meteringQuantityUnsent?.toFixed(2),
  }));
  return dataArray;
};
export const getTransformedCardsData = (data) => [
  { label: "Revenue Earned ($) till Date", value: `$ ${data?.totalRevenueEarned?.toFixed(2)}` },
  { label: "Savings Delivered till Date", value: `$ ${data?.totalSavingsDelivered?.toFixed(2)}` },
  { label: "Last 3 Months Metering Sent", value: `$ ${data?.last3MonthMeteringSent?.toFixed(2)}` },
  { label: "Last 3 Months Savings", value: `$ ${data?.last3MonthSavingsDelivered?.toFixed(2)}` },
  { label: "Last Month Metering Sent", value: `$ ${data?.lastMonthMeteringSent?.toFixed(2)}` },
  { label: "Metering Quantity", value: `$ ${data?.meteringQuantity?.toFixed(2)}` },
];
let __debounceTimer;
export function debounce(cbFuntion, time) {
  return function () {
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    if (__debounceTimer) {
      clearTimeout(__debounceTimer);
    }
    __debounceTimer = setTimeout(() => {
      cbFuntion.call(context, ...args);
    }, time);
  };
}

// const betterFunction = debounce(callSearch, 500);

export function toCamelCase(inputString) {
  return inputString.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (_, match) => match.toUpperCase());
}

export function extractMonthYearFromUrl(queryString) {
  // Use a regular expression to match the month and year values
  const match = queryString && queryString.match(/month=(\d{2})&year=(\d{4})/);

  // Check if the match is found
  if (match) {
    return {
      savedMonth: match[1],
      savedYear: match[2],
    };
  }
  return {};
}

export const getCookie = (name) => {
  const cookies = document.cookie.split(";").map((cookie) => cookie.trim());
  const cookie = cookies.find((cookieFound) => cookieFound.startsWith(`${name}=`));
  return cookie ? cookie.split("=")[1] : null;
};

export function stringToFixedNumber(value = "", fixed = 2) {
  return Number(value?.toFixed(fixed));
}

const byteKBConverter = (kbSize, convertTo) => {
  switch (convertTo) {
    case "MB":
      return `${(kbSize / 1024).toFixed(1)} MB`;
    default:
      return `${kbSize.toFixed(1)} KB`;
  }
};

export function byteConverter(size, convertTo, fallbackConverter) {
  const rawSize = size / 1024; // KB
  if (!fallbackConverter) {
    return byteKBConverter(rawSize, convertTo);
  }
  if (rawSize < 1024) {
    return `${rawSize.toFixed(1)} KB`;
  }
  return byteKBConverter(rawSize, fallbackConverter);
}

export const findCustomerIdFromURL = () => {
  const data = new URLSearchParams(window.location.search);
  const customerId = data.get("ci");
  return customerId;
};

export const isPathExist = (path) => {
  const dashboard = window.location.pathname;
  return dashboard.includes(path);
};

export const isRecommendationsOnFinops = () => {
  const userData = getLocalStorageUserData();
  const customerId = findCustomerIdFromURL();
  let isdashboardOnFinops = false;
  if (userData) {
    const {
      tokens: { moduleName },
    } = userData;
    if (
      customerId &&
      isPathExist(DASHBOARD_ENUM.RECOMMENDATION) &&
      isCurrentModule(moduleName, MODULE_ENUM.FINOPS)
    ) {
      isdashboardOnFinops = true;
    }
  }
  return {
    isdashboardOnFinops,
    customerId,
  };
};

export const isValidModule = (userData) => {
  let isModuleValid = false;
  const { isdashboardOnFinops } = isRecommendationsOnFinops();
  if (isdashboardOnFinops) {
    isModuleValid = false;
  } else {
    isModuleValid = userData?.tokens?.moduleName;
  }
  return isModuleValid;
};

export const canRenderMavList = (userData, mavList) => {
  let showMavList = false;
  const { isdashboardOnFinops } = isRecommendationsOnFinops();
  if (isdashboardOnFinops) {
    showMavList = true;
  } else {
    showMavList =
      userData?.tokens?.moduleName &&
      !["ckauto", "finops"].includes(userData?.tokens?.moduleName) &&
      mavList &&
      mavList.length > 0;
  }
  return showMavList;
};

export const parseDateRangeString = (dateString) => {
  const dateParts = dateString ? dateString.split(" - ") : [];
  const startDateString = dateParts[0];
  const endDateString = dateParts[1];

  const startDate = moment(startDateString, "DD-MMM-YYYY").format("YYYY-MM-DD");
  const endDate = moment(endDateString, "DD-MMM-YYYY").format("YYYY-MM-DD");
  return {
    startDate,
    endDate,
  };
};

export function generateFiscalYearOptions(startDate, endDate) {
  const options = [];

  for (let year = startDate; year <= endDate; year += 1) {
    const fiscalYearLabel = `FY ${year}-${(year + 1).toString().slice(-2)}`;
    const option = {
      label: fiscalYearLabel,
      value: `${year + 1}`,
    };
    options.push(option);
  }

  return options.reverse();
}

export const generateMonthYearDropdown = (endYear, startYear) => {
  const months = moment.months();
  const endDate = moment(endYear, "YYYY-MM");
  const dropdown = [];

  for (let year = startYear; year <= endDate.year(); year += 1) {
    for (let month = 0; month < months.length; month += 1) {
      const date = moment({ year, month });

      if (date.isAfter(endDate, "month")) break;

      // Format the date value as needed
      const value = date.format("YYYY-MM-DD HH:mm:ss");
      dropdown.push({
        label: `${months[month]} ${year}`,
        value,
      });
    }
  }

  return dropdown.reverse();
};
