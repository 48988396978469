import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import usePermission from "../Permissions";
import useCurrentDashboard from "../Comments/hooks/useCurrentDashboard";
import commonService from "../../../Services/commonService";
import actions from "../../../Actions";
import store from "../../../store";

const withRouter = (Component) => {
  function Wrapper(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [hasPermission, ignoreRules, setPermission] = usePermission();
    const commentsConfig = store?.getState().commentsReducer.allDashboardComments || {};
    const { currentDashboard, dashboardId } = useCurrentDashboard();
    const fetchCommentsConfig = async () => {
      const commentsCountResponse = await commonService.getCommentsCount({ dashboardId });
      store?.dispatch(actions.CommentsAction("SAVE_COMMENTS_COUNT", commentsCountResponse?.data));
    };
    useEffect(() => {
      if (!commentsConfig[dashboardId]) {
        if (
          (currentDashboard?.props?.module?.name === "Analytics" ||
            currentDashboard?.props?.module?.name === "Auto Analytics") &&
          (currentDashboard?.id === "VRIDEFICIT" || currentDashboard?.id === "RI_INVENTORY")
        ) {
          fetchCommentsConfig();
        }
      }
    }, []);
    return (
      <Component
        navigate={navigate}
        hasPermission={hasPermission}
        ignoreRules={ignoreRules}
        state={location.state}
        location={location}
        setPermission={setPermission}
        {...props}
      />
    );
  }

  return Wrapper;
};

export default withRouter;
