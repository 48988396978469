import {
  exportCostByCluster,
  exportCostByInstanceType,
  exportCostByNamespace,
  exportCostByPurchaseType,
  exportCostByRegion,
  exportTop20CostUsageTableData,
  getCostByCluster,
  getCostByInstanceType,
  getCostByNameSpace,
  getCostByPurchase,
  getSummaryCardsData,
  getTop20InstanceTableData,
  getCudosDatabaseCards,
  getCostByRegion,
  getTotalAccountsData,
  exportCostByAccount,
  getCostEngine,
  exportCostByEngine,
  getRICoverageData,
  getCostByPricingType,
  getRecommendationSummary,
  exportCostByPricingType,
  getHighlightCardData,
  getCostByUsage,
  getCostByStorageType,
  exportCostByUsageType,
  exportCostByStorageType,
  getDataTransferCostBreakdown,
  // getCostByType,
  getCostByService,
  getCostOfTypeByService,
  getCostOfServiceByType,
  exportCostByService,
  exportCostOfTypeByService,
  exportByResources,
  exportCostOfServiceByType,
  getDataTransferSummaryCards,
  getDynamoDBTableData,
  getDynamoDBDataExport,
} from "./service";
import MonthCards from "../Elasticache/components/MonthCards";
import ElasticSummaryCards from "../Elasticache/components/SummaryCards";
import SummaryCards from "../EKS/component/SummaryCards";
import {
  TOP_20_INSTANCE_HEADERS,
  TOP_20_INSTANCE_HEADERS_ELASTICACHE,
  TOP_20_INSTANCE_HEADERS_RDS,
  PALLETE_COLORS_CUDOS,
} from "../helper/constant";
import Routes from "../../../../../Config/Routes";
import TotalRiCoverageCost from "../Elasticache/components/TotalRICoverage";
import RecommendationSummary from "../Elasticache/components/RecommendationSummary";
import HighLightCards from "../Components/RDS/HighlightCards";
import DataTransferSummaryCards from "../DataTransfer/components/SummaryCards";
import DynamoDB from "../Database/DynamoDB";
import { getDatabaseData } from "../../CostBreakup/constant";

export const commonProps = {
  tabs: false,
  hideScroll: true,
  showLabels: "1",
  showMoreIcon: true,
  showGraphExpandIcon: true,
  xaxislinecolor: "#EDEFF0",
  yaxislinecolor: "#EDEFF0",
  legendItemFont: "Inter",
  divLineDashed: "0",
  divLineColor: "#D7D7D7",
  numVDivLines: "0",
  legendIconSides: 0,
  labelFontColor: "#2B2B2B",
  labelFontSize: 9,
  rotateLabels: 1,
  yAxisValueFontColor: "#2B2B2B",
  yAxisValueFontSize: 9,
  height: 326,
  labelStep: "0",
  anchorRadius: "2",
  palettecolors: PALLETE_COLORS_CUDOS.join(","),
};

const COST_BY_ACCOUNTS = (activeChartType) => ({
  componentType: "graph",
  type: activeChartType || "stackedcolumn2d",
  heading: "Cost by Account",
  name: "costByAccounts",
  serviceCall: getTotalAccountsData,
  exportServiceCall: exportCostByAccount,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Account Name",
    align: "Left",
  },
  ...commonProps,
});

const COST_BY_ENGINES = (activeChartType) => ({
  componentType: "graph",
  type: activeChartType || "stackedcolumn2d",
  heading: "Cost by Engine",
  name: "costByEngines",
  serviceCall: getCostEngine,
  exportServiceCall: exportCostByEngine,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Engine",
    align: "Left",
  },
  ...commonProps,
});

const COST_BY_REGION = (activeChartType) => ({
  componentType: "graph",
  type: activeChartType || "stackedcolumn2d",
  heading: "Cost by Region",
  name: "costByRegions",
  serviceCall: getCostByRegion,
  exportServiceCall: exportCostByRegion,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Regions",
    align: "Left",
  },
  ...commonProps,
});

const COST_BY_INSTANCE_TYPE = (activeChartType) => ({
  componentType: "graph",
  type: activeChartType || "stackedcolumn2d",
  heading: "Cost by Instance Type",
  serviceCall: getCostByInstanceType,
  exportServiceCall: exportCostByInstanceType,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Instance Type",
    align: "Left",
  },
  name: "costByInstanceType",
  ...commonProps,
});

const COST_BY_PRICING_TYPE = (activeChartType) => ({
  componentType: "graph",
  type: activeChartType || "stackedcolumn2d",
  heading: "Cost by Pricing Type",
  serviceCall: getCostByPricingType,
  exportServiceCall: exportCostByPricingType,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Pricing Type",
    align: "Left",
  },
  name: "costByPricingType",
  ...commonProps,
});

const COST_BY_PURCHASE_TYPE = (activeChartType) => ({
  componentType: "graph",
  type: activeChartType || "stackedcolumn2d",
  heading: "Cost by Purchase Type",
  name: "costByPurchaseType",
  serviceCall: getCostByPurchase,
  exportServiceCall: exportCostByPurchaseType,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Purchase Type",
    align: "Left",
  },
  ...commonProps,
});

const COST_BY_USAGE = (activeChartType) => ({
  componentType: "graph",
  type: activeChartType || "stackedcolumn2d",
  heading: "Cost by Usage",
  name: "costByUsage",
  serviceCall: getCostByUsage,
  exportServiceCall: exportCostByUsageType,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Purchase Type",
    align: "Left",
  },
  ...commonProps,
});

const COST_BY_STORAGE_TYPE = (activeChartType) => ({
  componentType: "graph",
  type: activeChartType || "stackedcolumn2d",
  heading: "Cost by Storage Type",
  name: "costByStorageType",
  serviceCall: getCostByStorageType,
  exportServiceCall: exportCostByStorageType,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Purchase Type",
    align: "Left",
  },
  ...commonProps,
});

const TOP_20_TABLE_CONSTANT = {
  componentType: "table",
  heading: "Top 20 Node Instance Usage by Total Cost",
  headers: TOP_20_INSTANCE_HEADERS,
  className: "",
  serviceCall: getTop20InstanceTableData,
  exportServiceCall: exportTop20CostUsageTableData,
};

const TOP_20_TABLE_CONSTANT_ELASTICACHE = {
  componentType: "table",
  headers: TOP_20_INSTANCE_HEADERS_ELASTICACHE,
  className: "",
  serviceCall: getTop20InstanceTableData,
  exportServiceCall: exportTop20CostUsageTableData,
};

const TOP_20_TABLE_CONSTANT_RDS = {
  componentType: "table",
  headers: TOP_20_INSTANCE_HEADERS_RDS,
  className: "",
  serviceCall: getTop20InstanceTableData,
  exportServiceCall: exportTop20CostUsageTableData,
};

const COST_BREAKDOWN = {
  name: "Top20InstanceTable",
  componentType: "multiLevel-table",
  heading: "Top 20 Resources",
  serviceCall: getDataTransferCostBreakdown,
  exportServiceCall: exportByResources,
};

const EKS_CONSTANT = (activeChartType) => ({
  monthCards: {
    componentType: "custom",
    component: MonthCards,
    className: "w-[35%]",
    serviceCall: getCudosDatabaseCards,
  },
  summaryCards: {
    componentType: "custom",
    component: SummaryCards,
    className: "w-[48%]",
    serviceCall: getSummaryCardsData,
  },
  recommendationSummary: {
    componentType: "custom",
    component: RecommendationSummary,
    className: "w-[calc(100%-83%-8px)]",
    serviceCall: getRecommendationSummary,
  },
  costByClusters: {
    componentType: "graph",
    type: activeChartType || "stackedcolumn2d",
    heading: "Cost by Clusters",
    name: "costByClusters",
    serviceCall: getCostByCluster,
    exportServiceCall: exportCostByCluster,
    isGranularityApplied: true,
    firstHeader: {
      key: "rowName",
      value: "Cluster Name",
      align: "Left",
    },
    wrapperClass: "w-[49%] !mt-[10px] !mb-[0px] graph-wrapper-style",
    ...commonProps,
  },
  costByNamespace: {
    componentType: "graph",
    type: activeChartType || "stackedcolumn2d",
    heading: "Cost by Namespace",
    name: "costByNamespace",
    serviceCall: getCostByNameSpace,
    exportServiceCall: exportCostByNamespace,
    isGranularityApplied: true,
    firstHeader: {
      key: "rowName",
      value: "Namespace",
      align: "Left",
    },
    wrapperClass: "w-[calc(51%-4px)] !mt-[10px] !mb-[0px] graph-wrapper-style",
    ...commonProps,
  },
  costByRegions: {
    wrapperClass: "w-[49%] !mt-[10px] !mb-[0px] graph-wrapper-style",
    ...COST_BY_REGION(activeChartType),
  },
  costByInstanceType: {
    wrapperClass: "w-[calc(51%-4px)] !mt-[10px] !mb-[0px] graph-wrapper-style",
    ...COST_BY_INSTANCE_TYPE(activeChartType),
  },
  costByPurchaseType: {
    wrapperClass: "w-[49%] !mt-[10px] !mb-[0px] graph-wrapper-style",
    ...COST_BY_PURCHASE_TYPE(activeChartType),
  },
  Top20InstanceTable: {
    wrapperClass: "w-[49.8%] !mt-[10px] !mb-[0px] graph-wrapper-style",
    ...TOP_20_TABLE_CONSTANT,
  },
});

const ELASTICACHE_CONSTANT = (activeChartType) => ({
  monthCards: {
    componentType: "custom",
    component: MonthCards,
    className: "w-[45%]",
    serviceCall: getCudosDatabaseCards,
  },
  summaryCards: {
    componentType: "custom",
    component: ElasticSummaryCards,
    className: "w-[35%] elasticache",
    serviceCall: getSummaryCardsData,
  },
  recommendationSummary: {
    componentType: "custom",
    component: RecommendationSummary,
    className: "w-[calc(20%-20px)]",
    serviceCall: getRecommendationSummary,
  },
  costByAccounts: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(54%-4px)]",
    ...COST_BY_ACCOUNTS(activeChartType),
  },
  costByRegions: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[46%]",
    ...COST_BY_REGION(activeChartType),
  },
  costByEngines: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(40%-4px)]",
    ...COST_BY_ENGINES(activeChartType),
  },
  costByInstanceType: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style grow !w-[60%]",
    ...COST_BY_INSTANCE_TYPE(activeChartType),
  },
  RICoverageCost: {
    componentType: "custom",
    component: TotalRiCoverageCost,
    heading: "RI Coverage in Cost",
    name: "RICoverageCost",
    serviceCall: getRICoverageData,
  },
  costByPricingType: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style grow !w-[calc(55%-4px)]",
    ...COST_BY_PRICING_TYPE(activeChartType),
  },
  Top20InstanceTable: TOP_20_TABLE_CONSTANT_ELASTICACHE,
});

const RDS_CONSTANT = (activeChartType) => ({
  monthCards: {
    componentType: "custom",
    component: MonthCards,
    className: "rds w-[63%] rds",
    serviceCall: getCudosDatabaseCards,
  },
  summaryCards: {
    componentType: "custom",
    component: ElasticSummaryCards,
    className: "w-[calc(37%-5px)] rds",
    serviceCall: getSummaryCardsData,
  },
  highlightCards: {
    componentType: "custom",
    component: HighLightCards,
    className: "w-[80%]",
    serviceCall: getHighlightCardData,
  },
  recommendationSummary: {
    componentType: "custom",
    component: RecommendationSummary,
    className: "w-[calc(20%-20px)]",
    serviceCall: getRecommendationSummary,
  },
  costByAccounts: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(53%)]",
    ...COST_BY_ACCOUNTS(activeChartType),
    type: activeChartType || "stackedcolumn2d",
  },
  costByEngines: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(47%-5px)]",
    ...COST_BY_ENGINES(activeChartType),
  },
  costByRegions: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[40%]",
    ...COST_BY_REGION(activeChartType),
  },
  costByUsage: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(60%-4px)]",
    ...COST_BY_USAGE(activeChartType),
  },
  RICoverageCost: {
    componentType: "custom",
    component: TotalRiCoverageCost,
    heading: "RI Coverage in Cost",
    name: "RICoverageCost",
    serviceCall: getRICoverageData,
  },
  costByPricingType: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style  !w-[calc(55%-4px)]",
    ...COST_BY_PRICING_TYPE(activeChartType),
  },
  costByStorageType: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[59%]",
    ...COST_BY_STORAGE_TYPE(activeChartType),
  },
  costByInstanceType: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style  !w-[calc(41%-4px)]",
    ...COST_BY_INSTANCE_TYPE(activeChartType),
  },
  Top20InstanceTable: TOP_20_TABLE_CONSTANT_RDS,
});

const COST_BY_SERVICE = () => ({
  componentType: "graph",
  type: "stackedcolumn2d",
  heading: "Cost by Service",
  name: "costByService",
  serviceCall: getCostByService,
  exportServiceCall: exportCostByService,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Engine",
    align: "Left",
  },
  ...commonProps,
});
const COST_OF_TYPE_BY_SERVICE = () => ({
  componentType: "graph",
  type: "stackedcolumn2d",
  heading: "Cost of Type by Service",
  name: "costOfTypeByService",
  tabArr: [],
  serviceCall: getCostOfTypeByService,
  exportServiceCall: exportCostOfTypeByService,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Regions",
    align: "Left",
  },
  ...commonProps,
});
const COST_OF_SERVICE_BY_TYPE = () => ({
  componentType: "graph",
  type: "stackedcolumn2d",
  heading: "Cost of Service by Type",
  name: "costOfServiceByType",
  tabArr: [],
  serviceCall: getCostOfServiceByType,
  exportServiceCall: exportCostOfServiceByType,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Regions",
    align: "Left",
  },
  ...commonProps,
});
const DATA_TRANSFER = (activeChartType) => ({
  monthCards: {
    componentType: "custom",
    component: MonthCards,
    className: "w-[38%]",
    serviceCall: getCudosDatabaseCards,
  },
  summaryCards: {
    componentType: "custom",
    component: DataTransferSummaryCards,
    className: "w-[42%] data-transfer",
    serviceCall: getDataTransferSummaryCards,
  },
  recommendationSummary: {
    componentType: "custom",
    component: RecommendationSummary,
    className: "w-[calc(20%-20px)] data-transfer",
    serviceCall: getRecommendationSummary,
  },
  costByAccounts: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(53%)]",
    ...COST_BY_ACCOUNTS(activeChartType),
    firstHeader: {
      ...COST_BY_ACCOUNTS(activeChartType).firstHeader,
      value: "Account Name",
    },
  },
  costByUsage: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(47%-5px)]",
    ...COST_BY_USAGE(activeChartType),
    firstHeader: {
      ...COST_BY_ACCOUNTS(activeChartType).firstHeader,
      value: "DataTransfer Type",
    },
    heading: "Cost by Type",
  },
  costByService: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[40%] ",
    ...COST_BY_SERVICE(activeChartType),
    firstHeader: {
      ...COST_BY_ACCOUNTS(activeChartType).firstHeader,
      value: "Service",
    },
  },
  costByRegions: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(60%-5px)]",
    ...COST_BY_REGION(activeChartType),
    firstHeader: {
      ...COST_BY_ACCOUNTS(activeChartType).firstHeader,
      value: "Region",
    },
  },
  costOfTypeByService: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(53%)]",
    ...COST_OF_TYPE_BY_SERVICE(activeChartType),
    firstHeader: {
      ...COST_BY_ACCOUNTS(activeChartType).firstHeader,
      value: "DataTransfer Type",
    },
  },
  costOfServiceByType: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(47%-5px)]",
    ...COST_OF_SERVICE_BY_TYPE(activeChartType),
    firstHeader: {
      ...COST_BY_ACCOUNTS(activeChartType).firstHeader,
      value: "Service",
    },
  },
  Top20InstanceTable: COST_BREAKDOWN,
});

export const DYNAMO_DB = {
  dynamoDBTable: {
    componentType: "custom",
    component: DynamoDB,
    className: "",
    name: getDatabaseData()?.selected || "",
    serviceCall: getDynamoDBTableData,
    exportServiceCall: getDynamoDBDataExport,
    showExcelButton: true,
  },
};

export const SELECTED_DASHBOARD_ENUM = (activeChartType = "") => ({
  eks: {
    name: "EKS",
    route: Routes.COST_BREAKUP_EKS,
    config: EKS_CONSTANT(activeChartType),
    showGranularitySidebar: true,
  },
  ElastiCache: {
    name: "ELASTICACHE",
    route: Routes.COST_BREAKUP_DATABASE,
    config: ELASTICACHE_CONSTANT(activeChartType),
    showGranularitySidebar: true,
  },
  RDS: {
    name: "RDS",
    route: Routes.COST_BREAKUP_DATABASE,
    config: RDS_CONSTANT(activeChartType),
    showGranularitySidebar: true,
  },
  DynamoDB: {
    name: "DynamoDB",
    route: Routes.COST_BREAKUP_DATABASE,
    config: DYNAMO_DB,
    showGranularitySidebar: false,
  },
  dataTransferNew: {
    name: "DATA_TRANSFER",
    showGranularitySidebar: true,
    route: Routes.COST_BREAKUP_DATA_TRANSFER_NEW,
    config: DATA_TRANSFER(activeChartType),
  },
});
