import React, { useState } from "react";
import cloneDeep from "lodash.clonedeep";
import Tabs from "./Tabs";
import Chart from "./Chart";
import Loader from "../../../../../Common/Loader";
import { getGraphDataNew } from "../../helper/utils";

function ExpandedGraph(props) {
  const { configuration, selectedTab, activeGranularity, apiKey } = props;
  const [selected, setSelected] = useState(selectedTab);
  const [graphLoading, setGraphLoading] = useState(false);
  const [config, setConfig] = useState(configuration);

  const tabClicked = async (item) => {
    if (item?.label === selected) {
      return;
    }
    setGraphLoading(true);
    setSelected(item?.label);
    configuration.selectedTab = item?.value;
    const response = await configuration.serviceCall(
      apiKey,
      configuration?.name,
      activeGranularity?.toUpperCase(),
      configuration
    );
    const configClone = cloneDeep(config);
    configClone.data = response?.data;
    setConfig(configClone);
    setGraphLoading(false);
  };

  return (
    <div className="expanded-graph w-[100%]">
      <Tabs
        tabs={configuration?.tabArr}
        tabClicked={tabClicked}
        selected={selected}
        wrapperClass="flex justify-center"
        isLoading={graphLoading}
      />
      {graphLoading ? (
        <div className="min-h-[346px] relative">
          <Loader />
        </div>
      ) : (
        <Chart {...props} graph={getGraphDataNew(config)} updatedWidth="965" />
      )}
    </div>
  );
}

export default ExpandedGraph;
