import React from "react";
import "./style.scss";

function SummaryCards(props) {
  const { summaryCardsData = {}, className = "" } = props;

  return (
    <div className={`flex gap-[5px] ${className}`}>
      {Object.keys(summaryCardsData?.count || {})?.map((category) => {
        const subItems = summaryCardsData?.dataList?.[category] || {};
        const actualSubItems = Object.keys(subItems || {});
        const expectedCount = summaryCardsData?.count?.[category] || 0;

        return (
          <div key={category} className="data-transfer-summary-cards">
            <div className="summary-cards-heading">{category}</div>
            <div className="summary-cards-content">
              {Array.from({ length: expectedCount })?.map((_, index) => {
                const subItem = actualSubItems?.[index] || "--";
                const value = subItems?.[subItem]?.value ?? "--";

                return (
                  <div
                    key={_}
                    style={{ width: `calc(100%/${expectedCount}` }}
                    className={`summary-cards-item-container ${
                      index < expectedCount - 1 ? "divider" : ""
                    }`}
                  >
                    <div className="summary-cards-item">{subItem}</div>
                    <div className="summary-cards-amount">{value}</div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default SummaryCards;
