import React from "react";
import { numberWithCommasandDecimal } from "../../../../../../../Utils/commonUtils";
import { ReactComponent as Arrow } from "../../../Assets/arrow.svg";
import CardImage from "../../../Assets/eks-recommendation.svg";

import "./style.scss";

function RecommendationSummary(props) {
  const { cardsData = {}, className = "" } = props;

  return (
    <div
      className={`ck-cudos-months-cards monthly-cost-cards ${className}`}
      style={{
        backgroundImage: `url(${CardImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <h4>{cardsData?.serviceName}</h4>
      <p>{cardsData?.titleLine}</p>
      <div className="flex items-baseline">
        <p className="amount">{numberWithCommasandDecimal(cardsData?.savings || 0)}</p>
        <Arrow
          onClick={() => {
            window.open(`/dashboard${cardsData?.link}`, "_blank");
          }}
          className="cursor-pointer"
        />
      </div>
    </div>
  );
}

export default RecommendationSummary;
