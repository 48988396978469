import React from "react";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import chroma from "chroma-js";
import { FormControl, Tooltip, Select, FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import moment from "moment";
import isEqual from "lodash.isequal";
import { ReactComponent as InfoIcon } from "../../../../Assets/icons/compute-info.svg";
import { ReactComponent as ResetIcon } from "../../../../Assets/icons/reset-icon.svg";
import { ReactComponent as SortedIcon } from "../../../../Assets/icons/sortingIcon.svg";
import {
  getLocalStorageUserData,
  noop,
  numberWithCommasandDecimal,
} from "../../../../Utils/commonUtils";
import { ModuleNameAzureInsights } from "../../../../Constants/textConstant";
import InfoBar from "./InfoBar";

export const showUsageGraphHEading = (heading) => (
  <div className="d_flex heading_wrapper">
    <h3 className="usageHeading">{!!heading && heading}</h3>
  </div>
);
export const itemValueResourceHTML = (item, index, toggleHandler) => (
  <>
    <ListItemText primary={item.value} onClick={() => toggleHandler(index, item.key)} />
    <Tooltip title="We are showing up top 1000 resources by cost." arrow placement="bottom">
      <span className="infoIcon">
        <InfoIcon fontSize="small" className="iconSize" />
      </span>
    </Tooltip>
  </>
);

export const includeExcludeListFormControl = (
  item,
  index,
  childValue = "",
  onIncludeChange = () => {}
) => (
  <FormControl>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={item.include}
      name="include"
      onChange={(event) =>
        item.value === "tags"
          ? onIncludeChange(event, index, childValue)
          : onIncludeChange(event, index)
      }
    >
      <MenuItem value="Clear Filter" id="clear-filter">
        Clear Filter
      </MenuItem>
      <MenuItem value="Include Only" id="include-filter">
        Include Only
      </MenuItem>
      <MenuItem value="Exclude Only" id="exclude-filter">
        Exclude Only
      </MenuItem>
    </Select>
  </FormControl>
);

export const selectAllFormControlLabel = (item, index, onSelectAll) => (
  <FormControlLabel
    className="selectAll_Check"
    control={<Checkbox size="small" />}
    label="Select All"
    value={item.selectAll}
    checked={item.selectAll}
    name="Select All"
    onChange={(event) => onSelectAll(event, index)}
  />
);

export const selectAllTagsFormControlLabel = (item, index, childValue, onSelectAll) => {
  const selectAllEnable = Object.values(item?.tagList?.[childValue]?.data).every(
    (value) => value === true
  );
  return (
    <FormControlLabel
      className="selectAll_Check"
      control={<Checkbox size="small" />}
      label="Select All"
      value={item.tagList[childValue].selectAll || selectAllEnable}
      checked={item.tagList[childValue].selectAll || selectAllEnable}
      name="Select All"
      onChange={(event) => onSelectAll(event, index, childValue)}
    />
  );
};

export const childFormControlLabel = (Child, item, index, childValue, onChange) => (
  <FormControlLabel
    control={<Checkbox size="small" />}
    label={Child}
    value={item?.tagList[childValue]?.data[Child]}
    checked={item?.tagList[childValue]?.data[Child]}
    name={Child}
    onChange={(event) => onChange(event, index, childValue)}
  />
);

export const filteredItemCount = (item) =>
  Object.keys(item?.childList).filter(
    (countItem) =>
      countItem.toLowerCase().includes(item?.searchText?.toLowerCase()) && countItem !== ""
  ).length;

export const ifItemNotTags = (item, index, onSelectAll, onInputChange, loading) =>
  item.value !== "tags" && (
    <>
      <div className="no_filter_added ">
        {" "}
        {item.arrayList.length ? (
          <div>{item.arrayList.length} Items Selected</div>
        ) : (
          "No filters currently added."
        )}
      </div>
      <TextField
        size="small"
        id="filter_search"
        value={item.searchText}
        placeholder="Search"
        // autoFocus={item.key === "resource" ? true : false}
        onChange={(e) => onInputChange(e, item.key, index)}
      />
      {!loading && (
        <>
          <div className="results_showing">
            Showing{" "}
            {item.key === "resource" && item?.searchText?.length < 4
              ? Object.keys(item?.childList)?.length
              : filteredItemCount(item)}{" "}
            results
          </div>
          {filteredItemCount(item) !== 0 ? (
            selectAllFormControlLabel(item, index, onSelectAll)
          ) : (
            <div className="no_data">No Data Available</div>
          )}
        </>
      )}
    </>
  );

export const noFilterCurrentlyAdded = (item, childValue) => {
  const filterLength = Object?.keys(item?.tagList?.[childValue]?.data)?.filter(
    (internalObj) => item?.tagList?.[childValue]?.data?.[internalObj] === true
  )?.length;

  return (
    <div className="no_filter_added ">
      {filterLength ? (
        <div>
          {filterLength}
          &nbsp; Items Selected
        </div>
      ) : (
        "No filters currently added."
      )}
    </div>
  );
};

export const innerCheckBoxListLessThan4 = (key, item, index, onChange) => (
  <div>
    {key !== "" && (
      <div className="inner_checkbox_list">
        <FormControlLabel
          control={<Checkbox size="small" />}
          label={key}
          value={item.childList[key]}
          checked={item.childList[key]}
          name={key}
          onChange={(event) => onChange(event, index)}
        />
      </div>
    )}
  </div>
);

export const innerCheckBoxListGreaterThan4 = (key, item, index, onChange, value, childValue) => (
  <div>
    <div className="inner_checkbox_list">
      <FormControlLabel
        control={<Checkbox size="small" />}
        label={key}
        value={value}
        checked={value}
        name={key}
        onChange={(event) => onChange(event, index, childValue)}
      />
    </div>
  </div>
);

export const filterButtons = (item, index, toggleHandler) => {
  <div className="filterButtons">
    <Button
      variant="outlined"
      size="small"
      id="close-filter"
      onClick={() => toggleHandler(index, item.key)}
    >
      Close
    </Button>
    <Button
      variant="contained"
      size="small"
      id="apply-filter"
      // onClick={() =>
      //   toggleHandler(index, item.key, true, key)
      // }
    >
      Apply
    </Button>
  </div>;
};
export const notShowChildSearchFilterTextField = (item, index, onInputChange) => (
  <TextField
    size="small"
    id="filter_search"
    value={item.searchText}
    placeholder="Search"
    onChange={(e) => onInputChange(e, item.key, index)}
  />
);

/**
 * Function to check if a selection is valid
 * @param {Array} selectedList - List of selected items
 * @param {Object} list - Child list object
 * @param {String} key - Key that indicates the type of selection being made. Default is an empty string.
 * @param {Object} data - Additional data object. Default is an empty object.
 * @returns {Boolean} - Boolean value indicating whether or not the selection is valid.
 */
export const returnCheckedHandler = (selectedList = [], childList = [], key = "", data = {}) => {
  if (key === "tags") {
    return !!(
      Object.keys(childList).some((item) => childList[item]) ||
      Object.keys(data?.tagList)?.some((item) => data?.tagList[item]?.arrayList?.length > 0)
    );
  }
  return selectedList.length > 0;
};

export const filterHeadingHtml = (resetFilters = noop) => (
  <div className="filter_heading">
    <h5>Filters</h5>
    <div className="resetButton" onClick={resetFilters} aria-hidden="true">
      <span>Reset All</span> <ResetIcon />
    </div>
  </div>
);

export const notShowChildResultShowingHTML = (item) => (
  <div className="results_showing">
    Showing{" "}
    {
      Object.keys(item.childList).filter(
        (countItem) =>
          countItem.toLowerCase().includes(item.searchText.toLowerCase()) && countItem !== ""
      ).length
    }{" "}
    results
  </div>
);

export const showChildResultShowingHTML = (item, childValue) => (
  <div className="results_showing">
    Showing &nbsp;
    {
      Object.keys(item?.tagList?.[childValue]?.data).filter(
        (countItem) =>
          countItem
            ?.toLowerCase()
            ?.includes(item?.tagList?.[childValue]?.searchTagText?.toLowerCase()) &&
          countItem !== ""
      )?.length
    }{" "}
    results
  </div>
);

export const showChildFilterButtons = (
  item,
  index,
  childValue,
  showChild,
  setShowChild,
  toggleHandler
) => (
  <div className="filterButtons">
    <Button
      variant="outlined"
      size="small"
      id="close-filter"
      onClick={() => setShowChild(!showChild)}
    >
      Close
    </Button>
    <Button
      variant="contained"
      size="small"
      id="apply-filter"
      onClick={() => toggleHandler(index, item.key, true, childValue)}
    >
      Apply
    </Button>
  </div>
);

export const searchTagListTextField = (item, index, childValue, onInputChange) => (
  <TextField
    size="small"
    id="filter_search"
    value={item?.tagList?.[childValue]?.searchTagText}
    placeholder="Search"
    onChange={(e) => onInputChange(e, item.key, index, childValue)}
  />
);

export const tagListChildListHTML = (
  item,
  index,
  showChild,
  tagFilterHandler,
  setShowChild,
  setChildValue
) => (
  <div className="tagList">
    {Object.keys(item?.childList).map((key, innerChild) => (
      <div className="check_Box_wrapper_external tags">
        {key !== "" && key.toLowerCase().includes(item?.searchText?.toLowerCase()) && (
          <div className="internal_tag_check_Box_wrapper ">
            <ListItemText
              primary={key}
              onClick={() => {
                tagFilterHandler(index, key, innerChild);
                setShowChild(!showChild);
                setChildValue(key);
              }}
            />
          </div>
        )}
      </div>
    ))}
  </div>
);

export const ifItemValueResourceListItemText = (item, index, toggleHandler) => (
  <ListItemText
    primary={item.value === "api operation" ? "API Operation" : item.value}
    onClick={() => toggleHandler(index, item.key)}
  />
);

export const getCostExplorerName = () => {
  const { tokens: { moduleName } = {} } = getLocalStorageUserData() || {};
  const moduleData = {
    lens: {
      name: "Cost Explorer",
      path: "costexplorer",
      breadcrumsData: ["Cost Analysis", "Cost Explorer"],
      savedReports: true,
    },

    [ModuleNameAzureInsights]: {
      name: "Cost Analysis",
      path: "costanalysis",
      breadcrumsData: ["Cost Analysis"],
    },
  };
  return moduleData[moduleName] || moduleData.lens;
};
export const manipulateFilterstoURL = (filters, stringify, newState) => {
  const res = [];
  filters?.forEach((key) => {
    const isInclude = key?.include === "Include Only" ? "true" : "false";
    if (!!newState && (!newState || key?.isApplied)) {
      if (key?.arrayList?.length > 0 && key?.key !== "tags") {
        const selectedListObj = {
          keys: key?.key,
          list: key?.arrayList,
          include: isInclude,
        };
        res.push(stringify ? JSON.stringify(selectedListObj) : selectedListObj);
      } else if (Object.keys(key?.tagList).length > 0) {
        const selectedTags = {};
        Object.keys(key?.tagList)?.forEach((item) => {
          if (key?.tagList?.[item]?.arrayList?.length) {
            selectedTags[item] = key?.tagList[item];
          }
        });
        const tagListObj = {
          keys: key?.key,
          list: selectedTags || {},
          include: isInclude,
        };
        res.push(stringify ? JSON.stringify(tagListObj) : tagListObj);
      }
    }
  });
  return res;
};
export const selectDateRange = {
  "Custom Range": "CUSTOM",
  "7D": "SEVEN_DAY",
  "3M": "THREE_MONTH",
  "6M": "SIX_MONTH",
  "1Y": "ONE_YEAR",
  MTD: "MTD",
  YTD: "YTD",
};

export const filterZeroData = (tableData = []) => tableData;

const getDataValue = (data, currencyRequired, defaultValue = "---", decimalPlaces = 2) => {
  if (typeof data === "string") {
    return data;
  }
  if (typeof data === "number") {
    return `${numberWithCommasandDecimal(data, decimalPlaces, currencyRequired)}`;
  }
  return defaultValue;
};

export const tableData = (row, item, currencyRequired) =>
  typeof row?.[item?.key] === "undefined"
    ? "NA"
    : getDataValue(row?.[item?.key], currencyRequired, item?.defaultValue, item?.decimalPlaces);

export const selectDateRangeReverse = {
  CUSTOM: "Custom Range",
  SEVEN_DAY: "7D",
  THREE_MONTH: "3M",
  SIX_MONTH: "6M",
  ONE_YEAR: "1Y",
  MTD: "MTD",
  YTD: "YTD",
};

export const returnBreadCrumbsData = (reportName) => {
  if (reportName) {
    return [...getCostExplorerName().breadcrumsData, reportName];
  }
  return getCostExplorerName()?.breadcrumsData;
};

export const manipulateRecentReportList = (data, reportId) => {
  if (reportId) {
    const firstObj = data.filter((item) => item.id === +reportId);
    const restArr = data.filter((item) => item.id !== +reportId);
    return [...firstObj, ...restArr.splice(0, 4)];
  }
  return data?.splice(0, 5) || [];
};
export const getInforBar = (text, isChartLoading) => (
  <InfoBar barText={text} isChartLoading={isChartLoading} />
);

export const getNewState = (state) => ({
  selectedgroupBy: state.selectedgroupBy,
  toggler: state.toggler,
  chartType: state.graph.chartType,
  granualityType: state.graph.granualityType,
  start: moment(state.start).format("YYYY/MM/DD"),
  end: moment(state.end).format("YYYY/MM/DD"),
  filters: manipulateFilterstoURL(state.filters, false, "newState"),
});

export const disableSaveBtn = (initState, newState, reportId) => {
  if (reportId) {
    return isEqual(initState, newState);
  }
  return false;
};

export const getReportOptions = (item) => ({ label: item?.reportName, value: item });

export const saveModalConfig = {
  openModal: false,
  heading: "Save to report library",
  touched: false,
  key: "save",
  reportName: "",
};

export const saveAsNewConfig = {
  openModal: false,
  heading: "Save as new report",
  touched: false,
  key: "saveAsNew",
  reportName: "",
};

export const isResource = (item) => item.key === "resource" && item.searchText?.length < 4;

export const replaceMatchingObjects = (arr1 = [], arr2 = [], key = "") =>
  arr1.map((obj1) => {
    const findObj = arr2.find((obj2) => obj1[key] === obj2[key]);
    if (findObj) {
      return findObj;
    }
    return obj1;
  });

export const getAppliedFillterList = (list = [], updatedElement = {}, tagName = "") => {
  const findIndex = list?.findIndex((ele) => ele?.key === updatedElement?.key);
  const newlist = [...list];
  if (findIndex !== -1 && tagName) {
    newlist[findIndex] = {
      ...newlist[findIndex],
      include: updatedElement?.include,
      tagList: {
        ...newlist[findIndex]?.tagList,
        [tagName]: updatedElement?.tagList?.[tagName],
      },
    };
    return newlist;
  }

  if (findIndex !== -1) {
    newlist[findIndex] = { ...updatedElement };
    return newlist;
  }
  return [...list, updatedElement];
};

export const heatMapColorGeneratorUtility = (minValue, maxValue, value) => {
  // background color
  const chromaScale = chroma.scale(["#F3F8FF", "#71A6F0"]).domain([0, 1]);

  // text color
  const text = chroma("black");
  const heatMapRatio = (value - minValue) / (maxValue - minValue);

  // text-color and background color set
  return Number.isNaN(value)
    ? { backgroundColor: "#ecf5fb" }
    : {
        backgroundColor: Number.isNaN(heatMapRatio) ? "#F3F8FF" : chromaScale(heatMapRatio).hex(),
        color: text.hex(),
      };
};
export const getHeaderContent = ({
  sortHandler = noop,
  heading = {},
  data = [],
  GraphQuantity = "",
  sortedIcon = true,
}) => (
  <>
    {" "}
    <div
      className="cursor-pointer inline-block"
      aria-hidden
      onClick={() => {
        sortHandler(heading?.key, data);
      }}
    >
      {heading?.value === "api operation" ? "API Operation" : heading?.value}
    </div>
    {(GraphQuantity === "" || sortedIcon) && (
      <span
        style={{ marginLeft: "4px", cursor: "pointer" }}
        aria-hidden
        onClick={() => {
          sortHandler(heading?.key, data);
        }}
      >
        <SortedIcon />
      </span>
    )}
  </>
);
