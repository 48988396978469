import React from "react";
import moment from "moment";
import TooltipV2 from "../../../../../Common/TooltipV2";
import DialogComponent from "../../../../../Common/Modal/Dialog";
import commonService from "../../../../../../Services/commonService";
import {
  getToast,
  downloadExcel,
  pushEventToGTM,
  getFormattedDate,
} from "../../../../../../Utils/commonUtils";
import Button from "../../../../../FormConfig/Form/Components/Button";
import copyIcon from "../../../../../../Assets/icons/copy-icon-primary-color.svg";

import CostBreakupDashboardHeader from "../../../CostBreakup/Comman/CostBreakupDashboardWrapper/CostBreakupDashboardHeader";
import BreadCrumb from "../../../../../Common/BreadCrumb";
import Text from "../../../../../FormConfig/Form/Components/Text";
import { ServiceEnum } from "../../../Virtual-reservation-customer/constant";
import PageTitle from "../../../../../Common/PageTitle";
import ComponentHeader from "../../../../Common/ComponentHeader";
import { renderBuyAgainModalBody } from "./modalBodyMapper";

export const copy = (data = "", message = "Copied") => {
  navigator.clipboard.writeText(data);
  getToast("success", message);
};

export const copyTooltip = (value, name = null, displayText = null, copyBtn = true) => (
  <TooltipV2
    parentClass="Edp_Tooltip_Style_V2 reserved-instances-copy-tooltip"
    tooltipClass="!justify-start"
    copyIcon={!copyBtn}
    copyText="Reservation ID copied"
    displayText={() => <div className="Edp_TooltipV2_Header">{displayText || value}</div>}
    data={value}
    anchorOrigin={{
      vertical: "center",
      horizontal: -10,
    }}
  >
    {name ? `${name}: ` : ""} <span className="font-semibold">{value}</span>
    {copyBtn && (
      <Button
        handleClick={() => {
          copy(value, `${name || ""} copied`);
        }}
        image={{
          className: "h-[16px] w-[16px]",
          src: copyIcon,
          alt: "copy icon",
        }}
      />
    )}
  </TooltipV2>
);

export const checkIfClickedOutside = (e, { state, componentRef, setState }) => {
  const { toggleDropdown } = state;
  if (toggleDropdown && componentRef.current && !componentRef.current.contains(e.target)) {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      toggleDropdown: false,
    }));
  }
};

export const loadHandler = (getSummaryData, ApiHandler, newTab = null, tab = "") => {
  const selected = new URLSearchParams(window.location.search);
  const selectedTab =
    selected.get("selected") === null
      ? getSummaryData()?.selected
      : selected.get("selected") || tab;
  const service = getSummaryData()?.enum?.[newTab || selectedTab];
  ApiHandler(service, newTab || selectedTab);
};

export const tabClicked = (
  selectedTab,
  index,
  e,
  { state, setState, getSummaryData, ApiHandler }
) => {
  pushEventToGTM({ eventName: "Tab-Clicked", selection: selectedTab });
  if (!e.ctrlKey && selectedTab !== state.selected) {
    setState((prevState) => ({
      ...prevState,
      selected: selectedTab,
      active: true,
      selectedSortHeader: "",
      downloadList: [],
    }));
    loadHandler(getSummaryData, ApiHandler, selectedTab);
  }
};

export const renderDownloadAndStatus = ({
  state,
  componentRef,
  dropDownToggler,
  DownlaodTabs,
  groupDetails,
  downlaodHandler,
  handleReservations,
  props,
}) => (
  <CostBreakupDashboardHeader
    isLoading={state?.isLoading}
    excelLoading={state?.excelLoading}
    customClass="!p-[0]"
    excelButtonData={{
      className: "!pt-[17px] !pb-[20px]",
      innerRef: componentRef,
      dropDownToggler,
      toggleDropdown: state?.toggleDropdown,
      DownlaodTabs,
      groupDetails,
      downloadList: state?.downloadList,
      downlaodHandler,
      icon: false,
      wraperClass: "!block",
    }}
    selectedView={state?.selectedView}
    selectedDate={state?.selectedDate}
    downloadSheet={state?.downloadSheet}
    location={{}}
    selectedUrl="/dashboard/summary"
    downloadButtonStyle="!h-auto !p-[10px] !font-medium"
    renderToggleTabButton
    renderToggleTabButtonData={{
      active: state?.active,
      ActiveTableData: state?.ActiveTableData,
      handleReservations,
      ExpireTableData: state?.ExpireTableData,
      firstButton: "Active",
      secondButton: "Expired",
    }}
    showMonthDropDown={false}
    openFrom="reservation"
    tableData={state?.tableData}
    {...props}
  />
);

const handleBuyAgainSave = async (state, setState, userData, modalCloseHandler) => {
  setState((prevState) => ({
    ...prevState,
    isLoading: true,
  }));
  try {
    const payload = { ...state?.modalData };
    const startDate =
      state?.modalData?.expiry_in_days < 0
        ? new Date().getTime()
        : getFormattedDate(state?.modalData?.end_date?.split("UTC")?.[0]?.trim());

    payload.start_date = moment(startDate).add(1, "hour").startOf("hour").valueOf();
    payload.end_date = getFormattedDate(state?.modalData?.end_date);
    payload.operation_type = "BUY_AGAIN";

    const params = {
      service: ServiceEnum[state?.selected?.toUpperCase()],
      customerId: userData?.tokens?.customerId || userData?.currentUser?.id,
    };

    const response = await commonService.VRPlaceOrder(params, [payload]);

    const {
      data: { message },
    } = response;

    modalCloseHandler();
    getToast("success", message, 5000);
  } catch (e) {
    getToast("error", e?.message);
  } finally {
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
    }));
    modalCloseHandler();
  }
};

export const renderBuyAgainModal = ({ userData, state, modalCloseHandler, setState }) => (
  <DialogComponent
    className="reserved-instances-buy-again-modal"
    id=""
    open={state?.buyAgainModal}
    handleClose={() => modalCloseHandler()}
    bodyClass="!border-0"
    showCloseButton={false}
  >
    {state?.modalData &&
      renderBuyAgainModalBody({ userData, state, modalCloseHandler, setState, handleBuyAgainSave })}
  </DialogComponent>
);

export const handleBuyAgain = (item, setState) => {
  setState((prevState) => ({
    ...prevState,
    buyAgainModal: true,
    modalData: item,
  }));
};

export const createHeaders = ({ service, state, setState, getSummaryData }) => {
  const { selected } = state;
  const updatedTableHeaders = getSummaryData((item) => handleBuyAgain(item, setState))
    ?.updatedTableHeaders[service || selected];
  setState((prevState) => ({
    ...prevState,
    tableHeaders: updatedTableHeaders,
  }));
  return updatedTableHeaders;
};

export const handleReservations = (status, { state, setState }) => {
  const { ExpireTableData, ActiveTableData } = state;
  if (status === "inActive") {
    setState((prevState) => ({
      ...prevState,
      tableData: ExpireTableData,
      active: false,
      downloadList: [],
    }));
  } else {
    setState((prevState) => ({
      ...prevState,
      tableData: ActiveTableData,
      active: true,
      downloadList: [],
    }));
  }
};

export const dropDownToggler = (state, setState) => {
  const { toggleDropdown } = state;
  setState((prevState) => ({
    ...prevState,
    toggleDropdown: !toggleDropdown,
  }));
};

export const groupDetails = ({ selectedData, state, getSummaryData, setState }) => {
  const { downloadList } = state;
  let updateddownloadList = [...downloadList];
  const { DownlaodTabs } = getSummaryData();

  if (selectedData === "ALL") {
    if (downloadList?.length === DownlaodTabs.length - 1) {
      updateddownloadList = [];
    } else {
      updateddownloadList = DownlaodTabs.map((item) => item.value).slice(1);
    }
    setState((prevState) => ({
      ...prevState,
      downloadList: updateddownloadList,
    }));
  } else if (updateddownloadList?.includes(selectedData)) {
    const updatedList = updateddownloadList.filter((singleItem) => singleItem !== selectedData);
    setState((prevState) => ({
      ...prevState,
      downloadList: updatedList,
    }));
  } else {
    updateddownloadList.push(selectedData);
    setState((prevState) => ({
      ...prevState,
      downloadList: updateddownloadList,
    }));
  }
};

export const downlaodHandler = async (state, setState) => {
  const { downloadList } = state;
  setState((prevState) => ({
    ...prevState,
    excelLoading: true,
  }));

  const DownloadSheetPayload = {
    month: 12,
    year: 2022,
    services: downloadList.toString(),
  };

  try {
    const response = await commonService.GetReservationSummaryDownload(DownloadSheetPayload);
    downloadExcel(response);
  } catch (err) {
    // Do nothing
  }
  setState((prevState) => ({
    ...prevState,
    excelLoading: false,
  }));
};

export const modalCloseHandler = (setState) => {
  setState((prevState) => ({
    ...prevState,
    buyAgainModal: false,
    acknowledge: false,
  }));
};

export const buyAgainButton = (item) => (
  <div className="expiry-days">
    {item?.expiry_in_days > 30 ? (
      <button type="button" onClick={() => handleBuyAgain(item)}>
        Buy Again
      </button>
    ) : null}
    {item?.expiry_in_days}
  </div>
);

export const filterArrayOfObjects = (arr, searchTerm, headers) =>
  arr.filter((obj) =>
    Object?.keys(obj)?.some((key) => {
      if (headers?.includes(key)) {
        if (typeof obj?.[key] === "string") {
          return obj?.[key]?.toLowerCase()?.includes(searchTerm?.toLowerCase());
        }
        if (Array?.isArray?.(obj?.[key])) {
          const searchArr = obj?.[key].map((item) => ({ ...item, checkboxId: undefined }));
          return JSON.stringify(searchArr)?.toLowerCase()?.includes(searchTerm);
        }
      }
      return false;
    })
  );

export const searchHandler = (term, { state, setState }) => {
  // Filter tableData based on search term and headers
  const filteredData = filterArrayOfObjects(
    state?.tableData,
    term?.target?.value,
    state?.tableHeaders?.map((item) => item.accessor)
  );
  setState((prevState) => ({
    ...prevState,
    searchedData: filteredData,
    searchTerm: term?.target?.value,
  }));
};

export const renderHeader = (pageTitle, pathArrayNew, infoMessage) => (
  <div>
    <BreadCrumb crumbs={pathArrayNew || []} />
    <PageTitle pageTitle={pageTitle || ""} />
    <Text
      text={pageTitle}
      size="text-[28px]"
      className="reserved-instance-header font-bold	ml-[20px]"
      type="primary"
    >
      {infoMessage()}
    </Text>
  </div>
);

const transformVRIData = (tableData) =>
  tableData.map((data) => ({
    ...data,
    endDateEpoc: data.end_date ? new Date(data.end_date).getTime() : "",
    startDateEpoc: data.end_date ? new Date(data.start_date).getTime() : "",
  }));

export const GetReservationSummary = async (costBreakupPayload, ErrorAction, setState) => {
  const ApiSummaryData = await commonService.GetReservationSummaryGet(costBreakupPayload);
  ErrorAction("200", 200);
  const summaryData = ApiSummaryData?.data?.data?.data;
  const updatedExpTable = [];
  const updatedActiveTable = [];

  summaryData.forEach((individualOption) => {
    if (individualOption.expiry_in_days < 0) {
      updatedExpTable.push(individualOption);
    } else {
      updatedActiveTable.push(individualOption);
    }
  });

  setState((prevState) => ({
    ...prevState,
    ActiveTableData: transformVRIData(updatedActiveTable),
    ExpireTableData: transformVRIData(updatedExpTable),
    tableData: transformVRIData(updatedActiveTable),
  }));
};

export const returnHeader = (breadcrumsData, pageTitle) => (
  <ComponentHeader
    breadcrumsData={breadcrumsData}
    selectedUrl=""
    customClass="!border-b-0 !pb-[0px]"
    headerName={pageTitle}
  />
);
