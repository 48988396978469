import React, { useState } from "react";
import cloneDeep from "lodash.clonedeep";
import ActionsDialog from "../../../../../../Common/Modal/ActionsDialog";
import SingleLevelTable from "../../../../CostExplorer/table";
import { sortHandler } from "../../../../CostBreakup/costbreakupUtils";
import Tabs from "../../../S3/graph/Tabs";
import Loader from "../../../../../../Common/Loader";
import { getGraphDataNew } from "../../../helper/utils";

import "./style.scss";

function TableModal(props) {
  const {
    showModal,
    setShowModal,
    graphTableHeader,
    graphtableData,
    graphTableFooter,
    heading,
    downloadSheetButton,
    selectedTab,
    tabs = [],
    configuration,
    activeGranularity,
    apiKey,
  } = props;
  const [ascending, setAscending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState(selectedTab);
  const [tableData, setTableData] = useState({
    data: graphtableData || [],
    footers: graphTableFooter,
    headers: graphTableHeader,
  });
  const sortHandlerParent = (sortBy) => {
    const updatedData = sortHandler(tableData.data, ascending, sortBy);
    setTableData({ ...tableData, data: [...updatedData] });
    setAscending(!ascending);
  };
  const tabClicked = async (item) => {
    if (item?.label === selected) {
      return;
    }
    setIsLoading(true);
    setSelected(item?.label);
    configuration.selectedTab = item?.value;

    const response = await configuration.serviceCall(
      apiKey,
      configuration?.name,
      activeGranularity?.toUpperCase(),
      configuration
    );

    const configClone = cloneDeep(configuration);
    configClone.data = response?.data;

    const data = getGraphDataNew(configClone);
    setTableData({
      data: data?.graphtableData,
      footers: data?.graphTableFooter,
      headers: data?.graphTableHeader,
    });
    setIsLoading(false);
  };

  return (
    <ActionsDialog
      open={showModal}
      data={{
        config: {
          heading,
          type: "Custom",
          actions: false,
          dialogClass: "modal_table_container",
          body: (
            <>
              <div className="table-modal-header">
                {tabs?.length ? (
                  <Tabs
                    tabs={tabs}
                    tabClicked={tabClicked}
                    selected={selected}
                    isLoading={isLoading}
                  />
                ) : (
                  <div />
                )}

                {downloadSheetButton(tableData?.data, selected)}
              </div>
              {isLoading ? (
                <div className="min-h-[200px]">
                  <Loader />
                </div>
              ) : (
                <div className="table-wrapper rds-table">
                  <div className="amazon_relation_table_wrapper">
                    <SingleLevelTable
                      headings={tableData?.headers}
                      data={tableData?.data || []}
                      usageFooter={false}
                      footer={tableData?.footers}
                      newFooter={tableData?.footers}
                      v2
                      sortHandler={(sortBy) => sortHandlerParent(sortBy)}
                      sortedIcon={false}
                      GraphQuantity={false}
                      message="No Data Available"
                      currencyRequired
                    />
                  </div>
                </div>
              )}
            </>
          ),
        },
      }}
      handleClose={() => setShowModal(false)}
      handleModalSubmit={() => setShowModal(false)}
      showCancelBtn={false}
      showCloseIcon
    />
  );
}

export default TableModal;
