/* eslint-disable import/no-cycle */
/* eslint-disable max-statements */
import cloneDeep from "lodash.clonedeep";
import { matchPath } from "react-router";
import commonService from "../Services/commonService";
import Routes from "../Config/Routes";
import {
  getDecryptedData,
  getLocalStorageUserData,
  isAutoDashboard,
  isEmpty,
  isAzureInsightsModule,
  isGcpInsightsModule,
  isLegacyLensModule,
  isLensModule,
  MAX,
  setLocalStorageUserData,
  getToast,
  isCurrentModule,
  isRecommendationsOnFinops,
} from "./commonUtils";
import { updatePartnerFavicon } from "../Components/Login/utils";
import { ModuleNameAzureInsights, ModuleNameLegacyLens } from "../Constants/textConstant";
import store from "../store";
import actions from "../Actions";
import { CUSTOMER_ROLE_RESET } from "../Components/CK-Lens/Constants/constant";
import { componentMapper } from "../Config/dynamicDashboard";
import { MODULE_ENUM } from "../Constants/finops/Constants";
import URLS from "../Services/axios/urls";

const userDataMappings = {
  tokens: ["accessToken", "idToken", "refreshToken", "expiresIn", "partnerId", "customerId"],
  loggedInUser: ["firstName", "lastName", "roleType"],
  currentUser: ["userRole"],
};

export const redirectUser = () => {
  const redirectPath = store.getState()?.DefaultPathReducer?.path;
  if (redirectPath) {
    return `/dashboard/${redirectPath}`;
  }
  return "/dashboard";
};
/**
 *
 * @param {*} userData
 * @returns @data
 * creates logged in user and tokens in localstorage
 */
export const createLoggedInUser = (userData) => {
  const data = {};

  Object.keys(userDataMappings).forEach((key) => {
    const value = userDataMappings[key];

    data[key] = {};

    value.forEach((item) => {
      if (typeof userData[item] === "object") {
        data[key] = { ...userData[item] };
      } else {
        data[key][item] = userData[item];
      }
    });
  });

  return data;
};

export const fetchPartnerData = async (props) => {
  const res = await Promise.all([
    commonService.getCurrentPartnerData({}),
    commonService.fetchPartnerLogo({}),
    commonService.fetchPartnerFavIcon(),
  ]);

  const response = res[0];
  const partnerLogo = res[1];
  const partnerFavIcon = res[2];

  updatePartnerFavicon(partnerFavIcon);

  if (response?.data?.data) {
    const {
      actions: { SaveUserDataAction },
    } = props;
    const partnerData = response?.data?.data;

    const userData = getLocalStorageUserData();
    userData.currentUser = { ...userData.currentUser, ...partnerData };
    userData.partnerLogo = partnerLogo.data.data;
    setLocalStorageUserData(userData);
    // localStorage.userData = JSON.stringify(userData);
    SaveUserDataAction("SAVE_USER_DATA", userData);
  }
  return null;
};

export const fetchMinMaxDates = async (props, defaultResponseRequired = false) => {
  const {
    actions: { AccountDateAction },
  } = props;
  const fetchDates = await commonService.fetchMinMaxDates({
    defaultResponseRequired,
  });
  const accountDates = {
    accountStartDate: fetchDates?.data?.data?.startDate || "",
    accountEndDate: fetchDates?.data?.data?.endDate || "",
  };
  AccountDateAction("SAVE_ACCOUNT_DATE", accountDates);
};

export const fetchMinMaxDatesCKAuto = async (props) => {
  const {
    actions: { AccountDateAction },
  } = props;
  const fetchDates = await commonService.fetchMinMaxDatesCKAuto();
  const accountDates = {
    accountStartDate: fetchDates?.data?.data?.startDate || "",
    accountEndDate: fetchDates?.data?.data?.endDate || "",
  };
  AccountDateAction("SAVE_ACCOUNT_DATE", accountDates);
};

export const getChildUrl = (child = [], journeyArray = []) => {
  child.forEach((item) => {
    if (item.path) {
      journeyArray.push(item.path);
    }
    if (item.childDashboards && item.childDashboards.length) {
      getChildUrl(item.child, journeyArray);
    }
  });
  return journeyArray;
};

export const getRecursiveDashboard = (menuData, parentName = "", sideMenuType = "") =>
  menuData.data.map((item) => {
    let menuItem = {};
    if (item.type === "SIDE_MENU" || item.type === sideMenuType) {
      menuItem = {
        name: item.displayName,
        url: `/dashboard/${item.path}`,
        parent: parentName,
        defaultOpen: item?.defaultOpen || item?.module?.name === "finops",
        icon: item?.icon,
        journey: item.path || getChildUrl(item?.childDashboards),
        tooltip: item?.tooltip || [],
        exactMatch: item?.exact,
        module: item?.module,
        accessList: item?.accessList || [],
      };

      if (item.childDashboards && item.childDashboards.length > 0 && item.type === "SIDE_MENU") {
        menuItem.children = true;
        menuItem.dashboardServices = getRecursiveDashboard(
          { data: item.childDashboards.filter((sideMenuObj) => sideMenuObj.type === "SIDE_MENU") },
          item.displayName
        );
      }
    }
    return menuItem;
  });

export const getDashboardRoutes = (menuData, updatedRoutes = [], tabs = []) => {
  const parentKey = menuData.parentKey || "";
  menuData.data.forEach((item) => {
    const menuItem = {
      component: componentMapper[item.key],
      props: {
        module: item?.module,
        pageTitle: item?.description,
        documentTitle: item?.title,
        accessList: item?.accessList,
        pathArray: item?.breadCrumbs || [],
        displayName: item?.displayName,
        dashboardId: item?.id,
        dashboardKey: item?.key,
        parentKey,
      },
      id: item?.key,
      path: item?.path?.split("?")[0],
    };
    if (item?.type === "TAB") {
      tabs.push(menuItem);
    }
    if (menuItem.component) {
      updatedRoutes.push(menuItem);
    }

    if (item.childDashboards && item.childDashboards.length) {
      getDashboardRoutes({ data: item.childDashboards, parentKey: item.key }, updatedRoutes, tabs);
    }
  });
  return [updatedRoutes, tabs];
};

const getBudgetAlertTypeList = (menuList, updatedList = [], typeName = "", getFlatArray = "") => {
  menuList?.data?.forEach((item) => {
    if (item.type.toLowerCase() === typeName) {
      if (getFlatArray) {
        updatedList.push({ ...item, parent: menuList?.parent || "" });
      } else {
        updatedList.push({
          id: item.id,
          name: item.displayName,
          accessList: item?.accessList || [],
          parent: menuList?.parent || "",
        });
      }
    }
    if (item.type) {
      getBudgetAlertTypeList(
        { data: item.childDashboards, parent: item.key },
        updatedList,
        typeName,
        getFlatArray
      );
    }
  });
  return updatedList;
};

export const getPreferenceList = (data) => {
  const { displayName, childDashboards = [], icon } = data;

  return [
    {
      name: displayName,
      url: `/dashboard/${childDashboards[0]?.path}`,
      parent: "",
      icon,
      dashboardServices: getRecursiveDashboard(
        { data: childDashboards },
        displayName,
        "PREFERENCES"
      )?.filter((_) => Object.keys(_)?.length),
    },
  ];
};

export const refreshUserRoutes = async (menuList, nonMav) => {
  // const userData = getDecryptedData(localStorage.getItem("userData"));
  const menuItems = menuList?.data?.data?.dashboardList || [];
  const newApiList = { data: [...menuItems] };
  store.dispatch(
    actions.DefaultPathAction("DEFAULT_PATH", { path: menuList?.data?.data?.defaultPath })
  );
  if (nonMav) {
    return {
      sidebarList: [],
      dashboardRoutes: [],
      key: Date.now(),
    };
  }

  let preferences = [];
  const sidebarList = [...getRecursiveDashboard(newApiList)];

  const preferenceData = newApiList?.data?.find((item) => item.key === "PREFERENCES");
  if (!isEmpty(preferenceData)) {
    preferences = getPreferenceList(preferenceData);
    sidebarList.push({
      name: "Preferences",
      url: `/dashboard/${preferenceData?.childDashboards?.[0]?.path}`,
      parent: "",
      icon: preferenceData.icon,
      journey: [],
    });
  }
  const [dashboardRoutes, tabs] = getDashboardRoutes(newApiList);

  const userRoutes = {
    sidebarList,
    dashboardRoutes,
    tabs,
    preferencesList: preferences,
    key: Date.now(),
  };

  userRoutes.customerSubMenu = getBudgetAlertTypeList(newApiList, [], "accordion", true) || [];
  userRoutes.budgetAlertTab = getBudgetAlertTypeList(newApiList, [], "alert");
  userRoutes.additionalButtons = getBudgetAlertTypeList(newApiList, [], "button", true);
  userRoutes.additionalTabs = getBudgetAlertTypeList(newApiList, [], "tab", true);
  // if (userData?.currentUser?.type === "CUSTOMER") {
  // }
  return userRoutes;
};

export const manageCustomerMenu = async (userData = getLocalStorageUserData(), mId = "") => {
  const moduleList = store?.getState()?.sidebarReducer?.moduleList;
  const moduleId = mId || moduleList?.map((item) => item?.id)?.join(",");
  const customerMenuList = await commonService.getUserDashboard({
    moduleId,
    type: userData?.tokens?.type || "CUSTOMER",
  });
  return customerMenuList;
};

const managePartnerMenu = async (userData) => {
  try {
    const menu = await commonService.getUserDashboard({ moduleId: userData?.tokens?.moduleId });
    return menu;
  } catch (err) {
    const errorState = {
      apiName: "module",
      data: userData,
      statusCode: err?.response?.data?.status,
    };
    return Promise.reject(errorState);
  }
};
export const setNotificationList = async (SidebarListRenderAction, moduleName = "") => {
  const apiPatObj = {
    // module wise api path object
    Billdesk: URLS.BILLDESK_GET_NOTIFICATION,
    default: URLS.CK_AUTO.GET_NOTIFICATION,
  };
  const apiPath = apiPatObj[moduleName] ? apiPatObj[moduleName] : apiPatObj.default;
  const response = await commonService.getNotificationList(apiPath);
  SidebarListRenderAction("NOTIFICATION_LIST", response?.data?.data || []);
};

const savePartnerData = (userData, selectedRole) => {
  if (selectedRole === "CUSTOMER") {
    const userDataClone = userData;
    userDataClone.partnerData = JSON.parse(JSON.stringify(userData?.currentUser));
    userDataClone.partnerData.xAuth = userData?.tokens?.xAuth;
  }
};
export const preloadImages = (imagePath) =>
  new Promise((resolve) => {
    const preload = imagePath;
    const image = new Image();
    image.src = preload;
    image.onload = resolve;
    image.onerror = resolve;
  });

export const removeSwitchRole = () => {
  const userData = getLocalStorageUserData();

  const {
    currentUser: { type },
  } = userData;
  if (type === "CUSTOMER") {
    // delete userData?.tokens?.xAuthUser;
    delete userData?.tokens?.xAuthUserMav;
    delete userData?.tokens?.mavId;
    delete userData?.tokens?.customerId;
    delete userData?.tokens?.moduleId;
    delete userData?.tokens?.xAuthUserCustomer;
    localStorage.removeItem("partnerName");
    localStorage.removeItem("partnerInfo");
    userData.tokens.xAuth = userData?.partnerData?.xAuth;
    userData.currentUser = JSON.parse(JSON.stringify(userData.partnerData));
  }

  if (type === "PARTNER") {
    delete userData?.tokens?.xAuth;
    delete userData?.currentUser;
    delete userData?.tokens?.moduleId;
    delete userData?.tokens?.moduleName;
    updatePartnerFavicon();
  }

  delete userData.partnerData;
  setLocalStorageUserData(userData);

  localStorage.removeItem("utilizationDates");

  return userData?.loggedInUser?.roleType;
};
export const cleanUpAuth = () => {
  const userData = getLocalStorageUserData();

  const {
    currentUser: { type },
  } = userData;
  if (type === "CUSTOMER") {
    // delete userData?.tokens?.xAuthUser;
    delete userData?.tokens?.xAuthUserMav;
    delete userData?.tokens?.mavId;
    delete userData?.tokens?.customerId;
    delete userData?.tokens?.moduleId;
    delete userData?.tokens?.moduleName;
    delete userData?.tokens?.xAuthUserCustomer;
    userData.tokens.xAuth = userData?.partnerData?.xAuth;
    userData.currentUser = JSON.parse(JSON.stringify(userData?.partnerData));
  }
  if (type === "PARTNER") {
    delete userData?.tokens?.moduleId;
    delete userData?.tokens?.moduleName;
  }

  delete userData.partnerData;
  setLocalStorageUserData(userData);
  return userData?.loggedInUser?.roleType;
};

export const routeExist = (navigate, type, data = []) => {
  const pathName = window.location.pathname.replace("/dashboard", "");
  const route = data.filter((item) =>
    matchPath(
      {
        path: `/${item.path}`,
        exact: true,
      },
      pathName
    )
  ).length;
  if (!route) {
    navigate(redirectUser(type));
  }
};

export const getUserCurrentRole = () => {
  const userData = getLocalStorageUserData();
  return userData?.currentUser?.type;
};

const setPartnerDisplayName = (displayName, partnerName, userDataClone) => {
  const userData = userDataClone;
  if (displayName) {
    userData.displayName = displayName;
  }
  if (partnerName) {
    userData.partnerName = partnerName;
  }
};

const getUserDetails = async (response, userData, dontUpdateData, role) => {
  const {
    data: {
      data: {
        permissions,
        userRole: currentRole = {},
        logo,
        favicon,
        currentUser = {},
        displayName,
        partnerName,
        s3BaseUrl,
      },
    },
  } = response;

  const userDataClone = cloneDeep(userData);
  const { loggedInUser } = userDataClone;
  const { firstName, lastName } = currentUser;

  if (dontUpdateData) {
    currentUser.type = currentRole.type;
    currentUser.typeLabel = currentRole.typeLabel;
    currentUser.roleLabel = currentRole.roleLabel;
    currentUser.role = currentRole.role;
    currentUser.userRoleType = currentRole.userRoleType;
    userDataClone.currentUser = { ...userDataClone.currentUser, ...currentUser };
  } else {
    currentRole.roleType = `${currentRole.typeLabel}-${currentRole?.role?.split("_").join(" ")}`;
    if (loggedInUser && (!loggedInUser.roleType || !userDataClone.currentUser)) {
      loggedInUser.roleType = currentRole.roleType;
    }
    userDataClone.currentUser = { ...userDataClone.currentUser, ...currentRole };
    userDataClone.loggedInUser = { ...userDataClone.loggedInUser, firstName, lastName };
  }
  if (permissions?.length) {
    const { 0: userPermissions } = permissions;
    userPermissions.permission = JSON.parse(userPermissions.meta);
    userDataClone.permission = userPermissions;
  }
  /**
   * this situation will occur when user had logged-in
   * and we are fetching its role first time
   */
  setPartnerDisplayName(displayName, partnerName, userDataClone);
  updatePartnerFavicon({ data: { data: favicon } });
  userDataClone.partnerLogo = logo;
  userDataClone.s3BaseUrl = s3BaseUrl;
  if (role === "CUSTOMER" || role === "PARTNER") await preloadImages(logo);

  return userDataClone;
};

const getCustomerUsersData = (users, props, userData) => {
  const {
    actions: { SidebarListRenderAction },
  } = props;
  const userDataNew = cloneDeep(userData);
  if (userData?.tokens?.moduleName !== "ckauto") {
    const USER_LIST = users?.data?.data?.content;

    if (!userData.tokens.customerId || !userDataNew.tokens.xAuthUserCustomer) {
      userDataNew.tokens.customerId = USER_LIST?.[0]?.id;
      userDataNew.tokens.xAuthUserCustomer = USER_LIST?.[0]?.name;
      setLocalStorageUserData(userDataNew);
    }
    SidebarListRenderAction("USER_LIST", [...USER_LIST]);
  }
  return userDataNew;
};

const getModuleList = (moduleListContent, props, userData) => {
  const {
    actions: { SidebarListRenderAction },
  } = props;

  const userDataNew = cloneDeep(userData);
  const MODULE_LIST = moduleListContent?.data?.data;
  SidebarListRenderAction("MODULE_LIST", [...MODULE_LIST]);
  if (!userData?.tokens?.moduleId) {
    userDataNew.tokens.moduleId = MODULE_LIST?.[0]?.id;
    userDataNew.tokens.moduleName = MODULE_LIST?.[0]?.name;
  }

  return userDataNew;
};

const projectCountAPI = (moduleId, moduleName) => {
  const moduleAPI = {
    ckauto: commonService.getProjectCount,
    [ModuleNameLegacyLens]: commonService.getProjectCountLegacyLens,
    [ModuleNameAzureInsights]: commonService.getProjectCountAzure,
  };
  return (
    moduleAPI?.[moduleName]?.({ moduleId }, { doNotSendMav: true }) ||
    moduleAPI?.ckauto?.({ moduleId }, { doNotSendMav: true })
  );
};

// const projectCountAPI = (moduleId, moduleName) =>
//   moduleName === ModuleNameLegacyLens || moduleName === ModuleNameAzureInsights
//     ? commonService.getProjectCountLegacyLens({ moduleId })
//     : commonService.getProjectCount({ moduleId });

const getProjectCountValue = (moduleName, projectCount) =>
  moduleName === ModuleNameLegacyLens || moduleName === ModuleNameAzureInsights
    ? projectCount?.data?.data?.customerOnboarded
    : projectCount?.data?.data;

const setMavList = (props, MAVList) => {
  const {
    actions: { SidebarListRenderAction },
  } = props;
  return SidebarListRenderAction("MAV_LIST", [...MAVList]);
};

export const getMAVList = async (props, userData, switchType) => {
  if (userData?.tokens?.partnerLevelMavId) {
    const userClone = userData;
    delete userClone?.tokens?.mavId;
    delete userClone?.tokens?.customersMID;
    delete userClone?.tokens?.partnerLevelMavId;
    delete userClone?.tokens?.xAuthUserMav;
  }
  const userDataNew = cloneDeep(userData);
  let MAVList = [];
  let selectedMAV = [];
  let ProjectCountValue = false;
  let doesMAVExist = false;
  const {
    tokens: { moduleId, moduleName },
  } = userData;
  try {
    const res = await Promise.all([
      commonService.getAllMAVNames({ moduleId }, { doNotSendMav: true }),
      projectCountAPI(moduleId, moduleName),
    ]);
    if (moduleName === ModuleNameAzureInsights) {
      const MAVExist = await commonService.getMavExist();
      doesMAVExist = MAVExist?.data?.data;
      userDataNew.tokens.doesMAVExist = doesMAVExist;
    }
    const [mav, projectCount] = res;
    MAVList = mav?.data?.data;
    selectedMAV = MAVList.filter((item) => item.id === userData.tokens.mavId);
    /**
     * removing mavId, xAuthUserMav, and xAuthUser when a user is
     * assigned multiple customer and in one customer mav is not
     * assigned. In this case deleting below keys from tokens
     * so that on reload it can be created again if user assign
     * mav to that customer and user reloads the page
     */
    if (!MAVList?.length || !selectedMAV.length) {
      if (userData.tokens.mavId && !selectedMAV.length && switchType !== "module")
        getToast(
          "error",
          "There are few changes in your Assigned/Unassigned MAV's. Please contact your admin for details."
        );

      delete userDataNew?.tokens?.mavId;
      delete userDataNew?.tokens?.xAuthUserMav;
    }
    ProjectCountValue = getProjectCountValue(moduleName, projectCount);
    store.dispatch(actions.ErrorAction(200, { status: 200, errorMessage: "" }));
  } catch (err) {
    const errorState = {
      statusCode: err?.response?.data?.status,
    };
    return Promise.reject(errorState);
  }
  if (!selectedMAV.length) {
    userDataNew.tokens.projectCount = ProjectCountValue;
    userDataNew.tokens.mavId = MAVList?.[0]?.id;
    userDataNew.tokens.xAuthUserMav = MAVList?.[0]?.name;
  }
  setLocalStorageUserData(userDataNew);
  setMavList(props, MAVList);
  return userDataNew;
};

const getMavListWhileRedirection = async (props, userData, switchType, customerId) => {
  const userDataNew = cloneDeep(userData);
  let MAVList = [];
  let selectedMAV = [];
  const data = new URLSearchParams(window.location.search);
  const custId = data.get("ci");
  try {
    const res = await commonService.getMavListOnPartner({
      customerId,
    });
    MAVList = res?.data?.data.mavList.map((i) => ({
      ...i,
      id: i?.mavId,
      name: i?.mavName,
    }));
    if (userData?.tokens?.customersMID) {
      selectedMAV = MAVList.filter((item) => item.id === userData.tokens.customersMID[custId]);
    }
    // selectedMAV = MAVList.filter((item) => item.id === userData?.tokens?.customersMID[custId]);
    if (!MAVList?.length || !selectedMAV.length) {
      if (userData.tokens.mavId && !selectedMAV.length && switchType !== "module")
        // getToast(
        //   "error",
        //   "There are few changes in your Assigned/Unassigned MAV's. Please contact your admin for details."
        // );

        delete userDataNew?.tokens?.mavId;
      delete userDataNew?.tokens?.xAuthUserMav;
    }
    store.dispatch(actions.ErrorAction(200, { status: 200, errorMessage: "" }));
  } catch (err) {
    const errorState = {
      statusCode: err?.response?.data?.status,
    };
    return Promise.reject(errorState);
  }
  if (!userDataNew.tokens.customersMID) {
    userDataNew.tokens.customersMID = {};
  }
  userDataNew.tokens.partnerLevelMavId = true;
  if (!selectedMAV.length) {
    userDataNew.tokens.mavId = MAVList?.[0]?.id;
    userDataNew.tokens.xAuthUserMav = MAVList?.[0]?.name;
    userDataNew.tokens.customersMID[custId] = MAVList?.[0]?.id;
  }
  setLocalStorageUserData(userDataNew);
  setMavList(props, MAVList);
  return userDataNew;
};

const currencyObject = {
  ARS: "ARS $",
  AUD: "AU$",
  BRL: "R$",
  CAD: "CA$",
  DKK: "kr.",
  EUR: "€",
  HKD: "HK$",
  INR: "₹",
  IDR: "Rp",
  JPY: "¥",
  KRW: "₩",
  CHF: "fr.",
  MYR: "RM",
  MXN: "MXN $",
  NZD: "NZ$",
  NOK: "kr.",
  RUB: "₽",
  SAR: "﷼",
  ZAR: "R",
  SEK: "kr.",
  TWD: "NT$",
  TRY: "₺",
  GBP: "£",
  USD: "$",
};

const getCurrency = async () => {
  if (isAzureInsightsModule() || isGcpInsightsModule()) {
    const currency = await commonService.fetchAzureCurrency();
    const currencyEnum = currency?.data?.data?.currency || "USD";
    localStorage.setItem("currency", currencyObject?.[currencyEnum]); // set Dynamic currency for azure
  } else {
    localStorage.setItem("currency", "$"); // set default currency dollar
  }
};

export const fetchUserMetaDataDetails = async (userData) => {
  const userDataClone = cloneDeep(userData);
  try {
    const mavId = userData?.tokens?.mavId;
    const metaDataPayload = {
      moduleId: userData?.tokens?.moduleId,
    };
    const userMetaData = await commonService.fetchUserMetaData(
      mavId ? `?mavId=${mavId}` : "",
      metaDataPayload
    );
    const loggedInUser = store.getState()?.sidebarReducer?.logedInUserData?.emailId;
    if (userMetaData?.data?.data?.customerName)
      window.posthog?.identify(loggedInUser, {
        email: loggedInUser,
        customerName: userMetaData?.data?.data?.customerName || null,
      });
    // userDataClone.tokens.xAuthUser = userMetaData.data.data.token;
    setLocalStorageUserData(userDataClone);
    getCurrency();
    store.dispatch(
      actions.ErrorAction(userMetaData.status, { status: userMetaData.status, errorMessage: "" })
    );
    return userDataClone;
  } catch (err) {
    const errorState = {
      statusCode: err?.response?.data?.status,
    };
    return Promise.reject(errorState);
  }
};

export const fetchMinMaxDatesAll = async (props, apiName, defaultResponseRequired = false) => {
  try {
    const {
      actions: { AccountDateAction },
    } = props;
    const fetchDates = defaultResponseRequired
      ? await commonService[apiName]({ defaultResponseRequired })
      : await commonService[apiName]();
    const accountDates = {
      accountStartDate: fetchDates?.data?.data?.startDate || "",
      accountEndDate: fetchDates?.data?.data?.endDate || "",
    };
    AccountDateAction("SAVE_ACCOUNT_DATE", accountDates);
    return null;
  } catch (err) {
    const errorState = {
      statusCode: err?.response?.data?.status,
    };
    return Promise.reject(errorState);
  }
};

const getPartnerUtils = async (userData, dontUpdateData, role, props) => {
  const {
    actions: { SaveUserDataAction },
  } = props;
  const response = await Promise.all([
    commonService.getUserMetaDataDetails(),
    commonService.getCurrentPartnerData({}),
  ]);
  const [userMetaData, partnerData] = response;
  const userDataClone = await getUserDetails(userMetaData, userData, dontUpdateData, role);
  if (partnerData?.data?.data) {
    const data = partnerData?.data?.data;
    userDataClone.currentUser = { ...userDataClone.currentUser, ...data };
  }
  SaveUserDataAction("S3_BASE_URL", userMetaData?.data?.data?.s3BaseUrl);
  return userDataClone;
};

const getModulesAPI = async (userData, props) => {
  let userDataClone = cloneDeep(userData);
  const view = userDataClone?.tokens?.type || "CUSTOMER";
  try {
    const modules = await commonService.getModuleList(
      { type: view },
      { doNotSendModule: true, doNotSendMav: true }
    );
    userDataClone = getModuleList(modules, props, userDataClone);
    // userDataClone = getModuleList(modules, props, userDataClone);
    store.dispatch(
      actions.ErrorAction(modules.status, { status: modules.status, errorMessage: "" })
    );
    return userDataClone;
  } catch (err) {
    const errorState = {
      apiName: "module",
      data: userDataClone,
      statusCode: err?.response?.data?.status,
    };
    return Promise.reject(errorState);
  }
};

const getCustomerUtils = async (userData, props, dontUpdateData, currentRole) => {
  const response = await Promise.all([
    commonService.getUserMetaDataDetails({ doNotSendMav: true }),
    commonService.getAllUsers(
      {
        pageNumber: 1,
        numberOfRecords: MAX,
      },
      { doNotSendMav: true }
    ),
  ]);
  const [userMetaData, users] = response;
  let userDataClone = await getUserDetails(userMetaData, userData, dontUpdateData, currentRole);
  userDataClone = getCustomerUsersData(users, props, userDataClone);
  return userDataClone;
};

const setUserdata = async (userDataClone, props, menuList, nonMav) => {
  const {
    actions: { SaveUserDataAction, SidebarListRenderAction },
  } = props;
  const dashboardRoutes = await refreshUserRoutes(menuList, nonMav);
  if (userDataClone.currentUser.type === "CLOUDONOMICS") {
    const userData = userDataClone;
    delete userData.tokens.moduleName;
  }
  setLocalStorageUserData(userDataClone);
  SaveUserDataAction("SAVE_USER_DATA", userDataClone);

  SidebarListRenderAction("RENDER_DASHBOARDLIST", dashboardRoutes);

  SidebarListRenderAction("HIDE_APP_LOADER", false);
  return dashboardRoutes;
};

const getPartnerModules = async (userData, props) => {
  try {
    const userDataNew = cloneDeep(userData);
    const moduleListContent = await commonService.getEnabledModuleList();
    const { isdashboardOnFinops } = isRecommendationsOnFinops();
    store.dispatch(actions.ErrorAction(200, { status: 200, errorMessage: "" }));
    const {
      actions: { SidebarListRenderAction },
    } = props;

    const MODULE_LIST = moduleListContent?.data?.data;
    SidebarListRenderAction("MODULE_LIST", [...MODULE_LIST]);
    if (!isdashboardOnFinops) {
      SidebarListRenderAction("MAV_LIST", []);
    }
    // here
    if (!userData.tokens.moduleId) {
      userDataNew.tokens.moduleId = MODULE_LIST?.[0]?.id;
      userDataNew.tokens.moduleName = MODULE_LIST?.[0]?.name;
    }
    return userDataNew;
  } catch (err) {
    const errorState = {
      apiName: "module",
      data: userData,
      statusCode: err?.response?.data?.status,
    };
    return Promise.reject(errorState);
  }
};

const getAutoMetaData = async (props) => {
  const {
    actions: { CkAutoCustomerMetaDataAction },
  } = props;
  const autoMetaData = await commonService.getCustomerAutoMetaData();
  CkAutoCustomerMetaDataAction("AUTO_CUSTOMER_METADATA", autoMetaData?.data?.data);
};

export const handleErrorResponse = async (error, userData, props) => {
  let userDataClone = cloneDeep(userData);
  if (
    error &&
    (error.statusCode === 786 ||
      error.statusCode === 715 ||
      error?.response?.status === 785 ||
      error?.response?.status === 403)
  ) {
    if (error?.apiName === "module") userDataClone = cloneDeep(error.data);

    const {
      actions: { SidebarListRenderAction },
    } = props;

    await setUserdata(userDataClone, props, [], true);
    SidebarListRenderAction("MAV_LIST", []);
    return Promise.reject(error);
  }
  return [];
};

export const processPartnerRole = async (
  userData,
  currentRole,
  props,
  dontUpdateData,
  switchType
) => {
  let userDataClone = cloneDeep(userData);
  const { navigate } = props;
  const {
    tokens: { moduleName },
  } = userData;
  try {
    let menuList = [];

    if (isCurrentModule(moduleName, MODULE_ENUM.FINOPS)) {
      const data = new URLSearchParams(window.location.search);
      const customerId = data.get("ci");
      if (customerId) {
        userDataClone = await getMavListWhileRedirection(props, userData, switchType, customerId);
        if (!userDataClone.tokens.customersMID) {
          userDataClone.tokens.customersMID = {};
        }
        userDataClone.tokens.mavId =
          userDataClone.tokens.customersMID[customerId] || userDataClone.tokens.mavId;
      }
    }

    if (switchType !== "module") {
      userDataClone = await getPartnerUtils(userDataClone, dontUpdateData, currentRole, props);
      userDataClone = await getPartnerModules(userDataClone, props);
    }
    menuList = await managePartnerMenu(userDataClone);
    const routes = await setUserdata(userDataClone, props, menuList, false);
    routeExist(
      navigate,
      "PARTNER",
      routes.dashboardRoutes.filter((item) => item.path !== "*")
    );
    if (isCurrentModule(userDataClone?.tokens?.moduleName, MODULE_ENUM.FINOPS)) {
      await fetchMinMaxDatesAll(props, "MonthlyDataStartDate");
    }
    // TODO 14 AUG 2024: NEED TO CHECK IF WE CAN MOVE THIS API CALL RESPONSE IN DETAILS API CALL
    if (
      isCurrentModule(userDataClone?.tokens?.moduleName, MODULE_ENUM.ANALYTICS) ||
      isCurrentModule(userDataClone?.tokens?.moduleName, MODULE_ENUM.AUTO_ANALYTICS)
    ) {
      try {
        const response = await commonService.getDashboardDates();
        const dashboardDates = response?.data?.data;
        if (Array.isArray(dashboardDates)) {
          localStorage.setItem("dashboardDates", JSON.stringify(dashboardDates));
        }
      } catch (error) {
        console.log(error);
      }
    }
    return routes;
  } catch (error) {
    // works when any api if giving error or if there is no mav for any user or no module and API gives 786
    const errorResp = await handleErrorResponse(error, userDataClone, props);
    return errorResp;
  }
};

export const processCustomerRole = async (
  userData,
  currentRole,
  props,
  dontUpdateData,
  switchType = null
) => {
  const {
    actions: { AccountDateAction },
  } = props;
  let userDataClone = cloneDeep(userData);
  try {
    if (switchType !== "module") {
      userDataClone = await getCustomerUtils(userDataClone, props, dontUpdateData, currentRole);
    }
    userDataClone = await getModulesAPI(userDataClone, props);
    userDataClone = await getMAVList(props, userDataClone, switchType);
    if (isLensModule(userDataClone) || isGcpInsightsModule()) {
      userDataClone = await fetchUserMetaDataDetails(userDataClone);
      await fetchMinMaxDatesAll(props, "fetchMinMaxDates");
    } else if (isLegacyLensModule(userDataClone) && userDataClone?.tokens?.projectCount) {
      userDataClone = await fetchUserMetaDataDetails(userDataClone);
      await fetchMinMaxDatesAll(props, "fetchMinMaxDates", true);
    } else if (isAutoDashboard()) {
      const moduleList = store?.getState()?.sidebarReducer?.moduleList;
      if (moduleList.filter((item) => item.name?.toLowerCase() === "ckauto").length) {
        await getAutoMetaData(props);
      }
      if (!userDataClone.tokens.projectCount) {
        userDataClone = await fetchUserMetaDataDetails(userDataClone);
        await fetchMinMaxDatesAll(props, "fetchMinMaxDatesCKAuto");
      }
    } else if (isAzureInsightsModule()) {
      if (userDataClone?.tokens?.projectCount && !!userDataClone?.tokens?.doesMAVExist) {
        userDataClone = await fetchUserMetaDataDetails(userDataClone);
        await fetchMinMaxDatesAll(props, "fetchMinMaxDates", true);
      } else {
        const accountDates = {
          accountStartDate: null,
          accountEndDate: null,
        };
        AccountDateAction("SAVE_ACCOUNT_DATE", accountDates);
      }
    }
    const menuList = await manageCustomerMenu(userDataClone);
    const dashboardRoutes = await setUserdata(userDataClone, props, menuList, false);
    return dashboardRoutes;
  } catch (error) {
    // works when any api if giving error or if there is no mav for any user or no module and API gives 786
    const errorResp = await handleErrorResponse(error, userDataClone, props);
    return errorResp;
  }
};

export const processCLNRole = async (userData, currentRole, props, dontUpdateData) => {
  let userDataClone = cloneDeep(userData);
  try {
    const response = await commonService.getUserMetaDataDetails();
    userDataClone = await getUserDetails(response, userDataClone, dontUpdateData, currentRole);
    const menu = await commonService.getUserDashboard();
    // function to get the api response
    const dashboardRoutes = await setUserdata(userDataClone, props, menu, false);
    store.dispatch(actions.ErrorAction(200, { status: 200, errorMessage: "" }));
    return dashboardRoutes;
  } catch (error) {
    return Promise.reject(error);
  }
};

// dontUpdateData is true in case of switch role only
export const fetchCurrentUserRole = async (
  userData,
  currentRole,
  props,
  dontUpdateData,
  switchType
) => {
  // const userDataClone = cloneDeep(userData);
  // const { navigate } = props;
  store.dispatch(
    actions.DashboardActions("INPROGRESS", {
      inprogress: true,
    })
  );
  localStorage.removeItem("currency");
  try {
    if (currentRole === "PARTNER") {
      return await processPartnerRole(userData, currentRole, props, dontUpdateData, switchType);
    }
    if (currentRole === "CUSTOMER") {
      return await processCustomerRole(userData, currentRole, props, dontUpdateData, switchType);
    }
    // Cloudonomic case
    return await processCLNRole(userData, currentRole, props, dontUpdateData);
  } catch (error) {
    // works when any api if giving error or if there is no mav for any user or no module and API gives 786
    // const errorResp = await handleErrorResponse(error, userDataClone, props);
    return error;
  } finally {
    store.dispatch(actions.DashboardActions("INPROGRESS", { inprogress: false }));
  }
};

// The function extracts the xAuth token from the response object and updates the user data accordingly. It then fetches the current user role using the updated data and navigates to the appropriate page based on the selected role.
export const saveSwitchRoleUser = async (
  // response,
  props,
  selectedItem,
  navigate,
  selectedRole,
  selectedPartner
) => {
  // const {
  //   data: { data: xAuth },
  // } = response;
  const {
    actions: { SidebarListRenderAction, ModifyRoutes },
  } = props;
  const userData = getLocalStorageUserData();
  savePartnerData(userData, selectedRole);
  userData.currentUser = { ...selectedItem };
  userData.currentUser.type = selectedRole;
  userData.tokens.partnerId = selectedPartner;
  userData.tokens.type = "CUSTOMER";
  // userData.tokens.xAuth = xAuth;
  setLocalStorageUserData(userData);
  ModifyRoutes("MODIFY_ROUTES", true);
  userData.passTunerToken = true;
  const dashboardRoutes = await fetchCurrentUserRole(userData, selectedRole, props, true);
  SidebarListRenderAction("RENDER_DASHBOARDLIST", dashboardRoutes);
  navigate(redirectUser(selectedRole));
  setTimeout(() => {
    ModifyRoutes("MODIFY_ROUTES", false);
  });
};

export const redirectAutoRoutes = async () => {
  const menuList = await manageCustomerMenu();
  const dashboardRoutes = await refreshUserRoutes(menuList, false);
  store.dispatch(actions.SidebarListRenderAction("RENDER_DASHBOARDLIST", dashboardRoutes));
  return redirectUser();
};

export const saveUserData = async (response, props, navigate) => {
  const {
    actions: { SaveUserDataAction },
  } = props;
  let { redirectUrl } = props;
  const { search } = window.location;
  const params = new URLSearchParams(search);
  redirectUrl = params.get("redirect-url");
  if (response?.data?.data) {
    // Clear Azure-related session data
    sessionStorage.removeItem("JWT");
    sessionStorage.removeItem("SSOTransactionID");
    // End of Azure-specific session data clearance
    const userData = createLoggedInUser(response.data.data);
    const userDataClone = { ...userData, redirectUrl };
    if (userDataClone?.currentUser?.type === "CUSTOMER") {
      userDataClone.currentUser = CUSTOMER_ROLE_RESET;
    }
    userDataClone.navigateAfterLogin = false;
    userDataClone.passTunerToken = false;
    const partnerModuleNames = getDecryptedData(localStorage?.getItem("partnerModuleNames") || []);
    userDataClone.partnerModuleNames = partnerModuleNames || [];
    SaveUserDataAction("SAVE_USER_DATA", userDataClone);
    setLocalStorageUserData(userDataClone);
    localStorage?.removeItem("partnerModuleNames");
    const roleType = response?.data?.data?.userRole?.type || "";
    if (roleType === "CUSTOMER") {
      navigate(Routes.DASHBOARD);
    } else {
      navigate(redirectUser(roleType));
    }
  }
};

export const updateSidebar = async (navigate, props) => {
  const userData = getLocalStorageUserData();
  await fetchCurrentUserRole(userData, userData?.currentUser?.type, props);
  navigate(redirectUser());
};
