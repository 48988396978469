import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import sidebarReducer from "./sidebarReducer";
import userReducer from "./userReducer";
import ErrorReducer from "./ErrorReducer";
import AccountDateReducer from "./AccountDateReducer";
import dashbaordReducer from "./dashboardReducer";
import BudgetAlertReducer from "./BudgetAlertReducer";
import CkAutoReducer from "./CkAutoReducer";
import LegacyLensReducer from "./LegacyLensReducer";
import showSwitchRoleReducer from "./showSwitchRoleResucer";
import ConfigDiscountsDataReducer from "./ConfigDiscountsDataReducer";
import ModifyRoutesReducer from "./ModifyRoutes";
import DefaultPathReducer from "./DefaultPathReducer";
import ComputeHourlyReducer from "./ComputeHourlyReducer";
import StrapiReducer from "./StrapiReducer";
import MarketPlaceBulkUploadReducer from "./MarketPlaceBulkUploadReducer";
import FileLoaderReducer from "./FileLoaderReducer";
import commentsReducer from "./CommentsReducer";

const RootReducer = combineReducers({
  sidebarReducer,
  userReducer,
  ErrorReducer,
  AccountDateReducer,
  dashbaordReducer,
  BudgetAlertReducer,
  CkAutoReducer,
  showSwitchRoleReducer,
  LegacyLensReducer,
  ConfigDiscountsDataReducer,
  ModifyRoutesReducer,
  DefaultPathReducer,
  ComputeHourlyReducer,
  StrapiReducer,
  MarketPlaceBulkUploadReducer,
  FileLoaderReducer,
  commentsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGIN_USER") {
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:root");
    // storage.removeItem('persist:otherKey')
    return RootReducer(undefined, action);
  }
  return RootReducer(state, action);
};

export default rootReducer;
